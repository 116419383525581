export function renderBlockHSlider (items , all ) {
    // console.log(" Items" ,  items);
    const artcl = items.filter(el => el.type === 'article');
    const goods = items.filter(el => el.type === 'product');
    const allArticles = all.filter(el => el.type === 'article');
    const allGoods = all.filter(el => el.type === 'product');
    let resArtcl = [];

    artcl.forEach(el => {
        const ar = allArticles.find(fnd => el.id === fnd.id)
        // console.log("AR" , ar);
        if(ar)resArtcl = [...resArtcl , ar];
    })

    goods.forEach(el => {
        const ar = allGoods.find(fnd => el.id === fnd.id)
        // console.log("AR" , ar);
        if(ar)resArtcl = [...resArtcl , ar];
    })

    return resArtcl ;
}

export function renderBlockContent (type , categ = 'all' , arArticles , arGoods) {
    const ctg = categ === null ? 'all' : categ ;
    const res = type === 'article' ? arArticles : arGoods;
    const result = ctg === 'all' ? res : res.filter(el => el.category === ctg);

    return result ;
}
