import React, {useCallback, useState} from "react";
import {Stack, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import {useLocales} from "../locales";
import StoriesComponent from "../sections/user/stories-component";
import {useSettingsContext} from "../components/settings";
import ArticleUsercardBlockWidget from "../sections/article/blocks/article-usercard-block-widget";
import Image from "../components/image";
import {useParams} from "../routes/hooks";

export default function UnknownUserPage() {
    const {t, currentLang} = useLocales()
    const { bot, start } = useParams()

    const {tgWebApp} = useSettingsContext()

    const paramsStart = start || 's';
    const link = `https://t.me/${bot}?start=${paramsStart}`


    const handleClickBtn = () => {
        tgWebApp.openTelegramLink(link)
    }

    return(
        <Container maxWidth="xs" sx={{px:2 , pt: 1 , mt:2 , mb:5}} >
                <Stack spacing={2} >
                    <Typography variant="h5" sx={{textAlign: "center" }}>Отлично! Готовы продолжить?</Typography>
                    <Typography variant="caption" sx={{textAlign: "center" , color: "text.disabled" }}>
                        Нажмите кнопку,чтобы не пропустить то , что для Вас приготовили
                    </Typography>
                    <Button size="large" variant="contained" color="success" onClick={handleClickBtn}>Продолжить</Button>
                </Stack>
            </Container>

    )
}
