import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  // article: [],
  products: [],
  // forms: [],
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.products = []
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ANALYTICS
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },


  },
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function setLoading(val){
    return (dispatch) => {
        dispatch(slice.actions.setLoading(val));
    }
}


export function getProducts(shopid, typeProduct) {

    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        // const response = await axios.get(`/statistic/products/${shopid}`);
        const response = await axios.get(`/statistic/${typeProduct}/${shopid}`);

        if(response?.data?.success){
          dispatch(slice.actions.getProductSuccess(response.data.data));
        }else{
          dispatch(slice.actions.hasError(response.data.data));
        }

      } catch (error) {
        console.error(error);
        dispatch(slice.actions.hasError(error));
      }
    };
  }
