import PropTypes, {string} from "prop-types";
import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {getCardByShopId} from "../../../redux/slices/lp";
import {useDispatch, useSelector} from "../../../redux/store";
// import UserManagerHscrollPanel from "../../user/user-manager-hscroll-panel";
import {useGetProducts} from "../../../api/product";
import {renderBlockHSlider , renderBlockContent} from "../../../utils/blocks";
import UserBannerProWidget from "../../user/user-banner-pro";
import UserCardTop from "../../user/user-card-top-head";
import {getStoriesById} from "../../../redux/slices/stories";
import StoriesComponent from "../../user/stories-component";
import {StoriesSkeleton} from "../../user/skeleton/stories-skeleton";



export default function ArticleStoriesWidget({id , onShow , open , sx}){

    const {  curShopId   } = useSelector((state) => state.startform);
    const {  current_stories , error ,  isLoading } = useSelector((state) => state.stories);
    const dispatch = useDispatch()

    const [showStories , setShowStories] = useState(open)

    const storyData = current_stories?.data || null


    useEffect(() => {
        // dispatch(getLpById("2"))
        dispatch(getStoriesById(curShopId , id , null))
    }, [dispatch, curShopId, id])

    const handleShowStop = () => {
        setShowStories(false)
        if(onShow)onShow(false)
    }


    return(
        <>

                <StoriesComponent
                    onClose={handleShowStop}
                    storyList={storyData}
                    loading={isLoading}
                    showStories={open}
                    // stepStories={handleStartStories}
                    // textLastSlideBtn={t('I understand')}
                />

        </>
    )
}

ArticleStoriesWidget.propTypes = {
    id: PropTypes.string ,
    sx: PropTypes.object ,
    onShow: PropTypes.bool ,
    open: PropTypes.bool ,
}
