/**
 * Рассчитывает контрастный цвет текста на основе цвета фона.
 * @param {string} backgroundColor - Цвет фона в формате HEX, RGB или RGBA.
 * @param {string} [pageBackgroundColor='#ffffff'] - Цвет подложки (по умолчанию белый).
 * @returns {string} - Контрастный цвет текста (#000000 или #ffffff).
 */
export const getContrastTextColor = (backgroundColor, pageBackgroundColor = '#ffffff') => {
    // Если цвет фона не передан или не является строкой, возвращаем значение по умолчанию
    if (!backgroundColor || typeof backgroundColor !== 'string') {
        return '#000000'; // Возвращаем черный цвет по умолчанию
    }

    // Преобразуем цвет фона в RGB или RGBA
    const parseColor = (color) => {
        // HEX
        if (color.startsWith('#')) {
            const hexToRgb = (hex) => {
                let hexColor = hex.startsWith('#') ? hex : `#${hex}`;
                const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                hexColor = hexColor.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
                const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
                return result
                    ? {
                        r: parseInt(result[1], 16),
                        g: parseInt(result[2], 16),
                        b: parseInt(result[3], 16),
                        a: 1, // Прозрачность по умолчанию
                    }
                    : null;
            };
            return hexToRgb(color);
        }

        // RGBA
        if (color.startsWith('rgba')) {
            const rgba = color.match(/[\d.]+/g);
            if (rgba && rgba.length >= 3) {
                return {
                    r: parseInt(rgba[0], 10),
                    g: parseInt(rgba[1], 10),
                    b: parseInt(rgba[2], 10),
                    a: parseFloat(rgba[3] || 1), // Прозрачность
                };
            }
        }

        // RGB
        if (color.startsWith('rgb')) {
            const rgb = color.match(/[\d.]+/g);
            if (rgb && rgb.length >= 3) {
                return {
                    r: parseInt(rgb[0], 10),
                    g: parseInt(rgb[1], 10),
                    b: parseInt(rgb[2], 10),
                    a: 1, // Прозрачность по умолчанию
                };
            }
        }

        return null; // Если цвет не распознан
    };

    // Преобразуем цвет фона и подложки
    const bgColor = parseColor(backgroundColor);
    const pageColor = parseColor(pageBackgroundColor);

    if (!bgColor || !pageColor) return '#000000'; // Если цвет не распознан, возвращаем черный

    // Смешиваем цвета с учетом прозрачности
    const mixColors = (bg, page, alpha) => ({
        r: Math.round(bg.r * alpha + page.r * (1 - alpha)),
        g: Math.round(bg.g * alpha + page.g * (1 - alpha)),
        b: Math.round(bg.b * alpha + page.b * (1 - alpha)),
    });

    const finalColor = mixColors(bgColor, pageColor, bgColor.a);

    // Рассчитываем яркость (luminance)
    const luminance = (0.299 * finalColor.r + 0.587 * finalColor.g + 0.114 * finalColor.b) / 255;

    // Возвращаем контрастный цвет
    return luminance > 0.5 ? '#000000' : '#ffffff';
};

export const setDefaultHeaderColorTg = (tgWebApp, defColor, offStories = false, checkLP = false) => {
    const isLP = /^\/lp\/\w{22,}$/.test(window.location.pathname);

    if (tgWebApp && !offStories && tgWebApp?.isVersionAtLeast('6.1')) {
        // Если checkLP true и isLP false, и цвет хедера отличается от defColor
        if (checkLP && !isLP && tgWebApp?.headerColor !== defColor) {
            tgWebApp.setHeaderColor(defColor);
        }
        // Если checkLP false, и цвет хедера отличается от defColor
        else if (!checkLP && tgWebApp?.headerColor !== defColor) {
            tgWebApp.setHeaderColor(defColor);
        }
    }
};

// Вспомогательная функция для проверки корректности цвета
export const isValidColor = (color) => {
    if (!color) return false; // Проверяем, что цвет не пустой
    // Простая проверка на HEX-формат (#FFFFFF или #FFF) или RGB/RGBA
    return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color) ||
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(,\s*[\d.]+)?\)$/.test(color);
}
