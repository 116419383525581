const DEFAULT_THRESHOLD = Math.sqrt(1.05 * 0.05) - 0.05;
const RE_HEX = /^(?:[0-9a-f]{3}){1,2}$/i;
const DEFAULT_BW = {
    black: '#000000',
    white: '#ffffff',
    threshold: DEFAULT_THRESHOLD
};

function padz(str, len = 2) {
    return str.padStart(len, '0');
}

function hexToRgbArray(hex) {
    if (hex.startsWith('#')) {
        hex = hex.slice(1);
    }
    // Handle 8-character HEX (RGBA) by trimming to 6 characters
    if (hex.length === 8) {
        hex = hex.slice(0, 6);
    }
    if (!RE_HEX.test(hex)) {
        throw new Error(`Invalid HEX color: "${hex}"`);
    }
    // Normalize / convert 3-chars hex to 6-chars.
    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }
    return [
        parseInt(hex.slice(0, 2), 16), // r
        parseInt(hex.slice(2, 4), 16), // g
        parseInt(hex.slice(4, 6), 16)  // b
    ];
}


function toRGB(c) {
    return { r: c[0], g: c[1], b: c[2] };
}

function toRgbArray(c) {
    if (!c) {
        throw new Error('Invalid color value');
    }
    if (Array.isArray(c)) {
        return c;
    }
    return typeof c === 'string' ? hexToRgbArray(c) : [c.r, c.g, c.b];
}

// http://stackoverflow.com/a/3943023/112731
function getLuminance(c) {
    const a = c.map(x => {
        const xNorm = x / 255;
        return xNorm <= 0.03928 ? xNorm / 12.92 : ((xNorm + 0.055) / 1.055) ** 2.4;
    });
    return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
}

function invertToBW(color, bw, asArr) {
    const options = (bw === true) ? DEFAULT_BW : { ...DEFAULT_BW, ...bw };
    const isLuminanceAboveThreshold = getLuminance(color) > options.threshold;

    if (isLuminanceAboveThreshold) {
        return asArr ? hexToRgbArray(options.black) : options.black;
    }

    return asArr ? hexToRgbArray(options.white) : options.white;
}


// -------------------------------
// PUBLIC MEMBERS
// -------------------------------


// WCAG контрастные коэффициенты
const WCAG_CONTRAST_RATIOS = {
    largeText: { AA: 3, AAA: 4.5 },
    regularText: { AA: 4.5, AAA: 7 }
};

/**
 * Calculates contrast ratio between two colors.
 * @param {Array} color1 - RGB array of the first color.
 * @param {Array} color2 - RGB array of the second color.
 * @returns {number} - The contrast ratio.
 */
function getContrastRatio(color1, color2) {
    const luminance1 = getLuminance(color1);
    const luminance2 = getLuminance(color2);
    const lighter = Math.max(luminance1, luminance2);
    const darker = Math.min(luminance1, luminance2);
    return (lighter + 0.05) / (darker + 0.05);
}

/**
 * Inverts the color to black or white based on WCAG accessibility levels.
 * @param {Array} color - RGB array of the color to invert.
 * @param {object|boolean} bw - Whether to amplify the inversion to black/white.
 * @param {boolean} asArr - Whether to return an array format.
 * @param {string} wcagLevel - WCAG level, either "AA" or "AAA".
 * @param {string} fontType - Font type, either "largeText" or "regularText".
 * @returns {string|Array} - Inverted color in hex or RGB array format.
 */
function invertToBWWithWCAG(color, bw, asArr, wcagLevel, fontType) {
    const options = (bw === true) ? DEFAULT_BW : { ...DEFAULT_BW, ...bw };
    const contrastRequirement = WCAG_CONTRAST_RATIOS[fontType][wcagLevel];

    const blackContrast = getContrastRatio(color, hexToRgbArray(options.black));
    const whiteContrast = getContrastRatio(color, hexToRgbArray(options.white));

    const highContrastColor = blackContrast >= contrastRequirement ? options.black : options.white;
    
    return asArr ? hexToRgbArray(highContrastColor) : highContrastColor;
}

/**
 * Generates inverted (opposite) version of the given color.
 * @param {Color} color - Color to be inverted.
 * @param {BlackWhite|boolean} [bw=false] - Whether to amplify the inversion to
 * black or white. Provide an object to customize black/white colors.
 * @returns {HexColor} - Hexadecimal representation of the inverted color.
 */
export function invert(color, bw = false, wcagLevel = null, fontType = "regularText") {
    color = toRgbArray(color);
    if (bw) {
        return wcagLevel 
            ? invertToBWWithWCAG(color, bw, false, wcagLevel, fontType)
            : invertToBW(color, bw);
    }
    return `#${color.map(c => padz((255 - c).toString(16))).join('')}`;
}

/**
 * Generates inverted (opposite) version of the given color, as a RGB object.
 * @param {Color} color - Color to be inverted.
 * @param {BlackWhite|boolean} [bw] - Whether to amplify the inversion to
 * black or white. Provide an object to customize black/white colors.
 * @returns {RGB} - RGB object representation of the inverted color.
 */
export function invertAsRGB(color, bw = false, wcagLevel = null, fontType = "regularText") {
    color = toRgbArray(color);
    // eslint-disable-next-line no-nested-ternary
    const list = bw 
        ? (wcagLevel ? invertToBWWithWCAG(color, bw, true, wcagLevel, fontType) : invertToBW(color, bw, true))
        : color.map(c => 255 - c);
    return toRGB(list);
}

/**
 * Generates inverted (opposite) version of the given color, as a RGB array.
 * @param {Color} color - Color to be inverted.
 * @param {BlackWhite|boolean} [bw] - Whether to amplify the inversion to
 * black or white. Provide an object to customize black/white colors.
 * @returns {Array} - RGB array representation of the inverted color.
 */

export function invertAsRgbArray(color, bw = false, wcagLevel = null, fontType = "regularText") {
    color = toRgbArray(color);
    // eslint-disable-next-line no-nested-ternary
    return bw
        ? (wcagLevel ? invertToBWWithWCAG(color, bw, true, wcagLevel, fontType) : invertToBW(color, bw, true))
        : color.map(c => 255 - c);
}

export function rgbaToHex(rgba) {
    const rgbaArray = rgba.match(/\d+/g);
    if (!rgbaArray || rgbaArray.length < 3) {
        console.error("Invalid RGBA format:", rgba);
        return "#000000"; 
    }
    const [r, g, b, a ] = rgbaArray.map(Number); 
    const alpha = Math.round(a * 255).toString(16).padStart(2, '0');
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}${alpha}`;
}



export const defaultThreshold = DEFAULT_THRESHOLD;


