import PropTypes from "prop-types";
import {AppBar, Button, Dialog, IconButton, Toolbar, Typography} from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import Iconify from "../../../components/iconify";
import ArticleFormView from "./article-form-view";
import { useLocales } from "../../../locales"

export default function ArticleFormDialog({open , formID , tgUser , handleClose }){
    const { t } = useLocales()
    return(
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}

        >
            <AppBar sx={{ position: 'relative' }}>
                {/* <AppBar position="fixed" > */}
                <Toolbar sx={{pl:1}}>

                    <Button
                        autoFocus
                        onClick={handleClose}
                        aria-label="close"
                        startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
                        sx={{ mt: 1  }}
                    >
                        {t(`system.back`)}
                    </Button>
                    <Typography sx={{ ml: 2, flex: 1 }}  component="div">
                        &nbsp;
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <Iconify icon="material-symbols:close" />
                    </IconButton>

                    {/* <Button autoFocus color="inherit" onClick={handleClose}> */}
                    {/*    Закрыть */}
                    {/* </Button> */}
                </Toolbar>
            </AppBar>
            <ArticleFormView formId={formID} tgID={tgUser?.id} tgUser={tgUser}/>
            <Box sx={{h:'300px'}}/>
        </Dialog>
    );
}

ArticleFormDialog.propTypes = {
    tgUser: PropTypes.object ,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    formID: PropTypes.string ,
}

