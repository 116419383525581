import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { FastAverageColor } from 'fast-average-color'

// @mui
import { Card, Typography, Box } from '@mui/material'
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import { alpha, useTheme } from "@mui/material/styles"
import { common } from '@mui/material/colors'
import { bgGradient } from "../../theme/css"
import Label from "../../components/label"
import Markdown from "../../components/markdown"
import ArticleMarkdownOL from "../article/articles/article-markdown-ol"
import Iconify from "../../components/iconify"

import { invert, invertAsRGB, invertAsRgbArray, rgbaToHex } from '../../components/color-invert/color-invert'
import { ArticleMarkdownButton } from "../article/articles/article-markdown-button"
import { useSettingsContext } from "../../components/settings"
import { TG_NULL_OBJECT } from "../../config-global"

// utils


// ----------------------------------------------------------------------

UserBannerProWidget.propTypes = {
    icon: PropTypes.node,
    sx: PropTypes.object,
    title: PropTypes.string,
    text: PropTypes.string,
    btnText: PropTypes.string,
    btnLink: PropTypes.string,
    color: PropTypes.string,
    useBackgroundColor: PropTypes.bool,
    startColor: PropTypes.string,
    endColor: PropTypes.string,
    gradientDeg: PropTypes.string,
    variant: PropTypes.string,
    iconPosition: PropTypes.string,
    buttonClick: PropTypes.func,
    iconWidth: PropTypes.number,
    textMarkdown: PropTypes.string,
    textForOl: PropTypes.array,
    textColor: PropTypes.string,
    textOpacity: PropTypes.string,
    usePikerColor: PropTypes.bool,
    textLabelColor: PropTypes.string,
    textLabelOpacity: PropTypes.string,
    useLabelPikerColor: PropTypes.bool,
    iconSize: PropTypes.number,
    label: PropTypes.string,
    useImagine: PropTypes.bool,
    imagine: PropTypes.string,
    buttonStyle: PropTypes.object,
    borderRadius: PropTypes.string,
}


const TgNullObj = process.env.NODE_ENV !== 'production' ? TG_NULL_OBJECT : null

export default function UserBannerProWidget({ title, text, icon, iconPosition = 'end', iconSize = 90,
    btnLink, btnText, color = 'black', imagine,useImagine,useBackgroundColor, startColor, endColor, gradientDeg, textLabelColor, textLabelOpacity = 100, useLabelPikerColor,
    variant, buttonClick, iconWidth = 94, textMarkdown, textForOl, textColor, textOpacity  = 100, usePikerColor, label, buttonStyle, borderRadius, sx, ...other }) {
    const { tgWebApp } = useSettingsContext()
    const theme = useTheme()
    const showBtn = (btnLink !== null && btnLink !== '')

    // const adjustedIconWidth = showBtn ? iconWidth * 1.6 : iconWidth * 1.4
    const marginTopValue = showBtn ? 1 : 2

    const [calculatedTextColor, setCalculatedTextColor] = useState('#FFFFFF')
    const [calculatedBlockLabelTextColor, setCalculatedBlockLabelTextColor] = useState('#000000')
    const [calculatedBackgroundColor, setCalculatedBackgroundColor] = useState('rgba(255, 255, 255, 0.2)')


    const cover = ''


    useEffect(() => {

        const updateColorsFromGradient = () => {
            if (startColor && endColor && gradientDeg) {
                const deg = parseInt(gradientDeg, 10)
                const isLeftAligned = iconPosition === 'start'

                // Логика выбора цвета в зависимости от положения и угла градиента
                const primaryColor = (isLeftAligned && deg >= 181 && deg <= 319) || (!isLeftAligned && (deg >= 0 && deg <= 180 || deg >= 320 && deg <= 360))
                    ? startColor
                    : endColor

                // Инвертируем выбранный цвет с учетом WCAG
                const invertedColor = invert(primaryColor, true, 'AA', 'regularText')
                const backgroundColor = alpha(invert(primaryColor, false), 0.2)

                const primaryColorHex = rgbaToHex(backgroundColor)
                // console.log('primaryColorHex',primaryColorHex)
                const invertedColorBlockLabel = invert(primaryColorHex, true, 'AA', 'regularText')


                // console.log('Primary Color based on Position and Degree:', primaryColor)
                //  console.log('Inverted Color with WCAG:', invertedColor)
                // console.log('Background Color:', backgroundColor)

                setCalculatedTextColor(invertedColor)
                setCalculatedBackgroundColor(backgroundColor)
                setCalculatedBlockLabelTextColor(invertedColorBlockLabel)
            }
        }

        updateColorsFromGradient()

    }, [color, cover, startColor, endColor, gradientDeg, title, iconPosition, label])

    const blockLabel = (
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ mt: 1 }}
        // sx={{ position: 'absolute', zIndex: 9, top: 16, right: 16 }}
        >
            <Label
                variant="soft"
                // color="secondary"
                sx={{
                    color: useLabelPikerColor ? textLabelColor : calculatedBlockLabelTextColor,
                    backgroundColor: calculatedBackgroundColor || 'black',
                    opacity: textLabelOpacity/100,
                }}
            >
                {label}
            </Label>

        </Stack>

    )


    const renderIcon = (

        <Box
            sx={{
                pl:0.5 ,
                width: iconSize,
                height: iconSize,
                lineHeight: 0,
                borderRadius: '50%',
                position: 'absolute',
                zIndex: 1,
                left: iconPosition === 'start' ? '0' : 'auto',
                right: iconPosition === 'end' ? '0' : 'auto',
            }}
        >
            {/* <Iconify icon={icon} width={iconOutside ? adjustedIconWidth : iconWidth} /> */}
            {/* <Iconify icon={icon} width={iconSize} /> */}

            {icon?.startsWith('<?xml') ? (
                // Если icon — это SVG-код, используем dangerouslySetInnerHTML для вставки SVG
                <span
                    style={{
                        display: 'inline-block',
                        width: iconSize,
                        height: iconSize,
                    }}
                    dangerouslySetInnerHTML={{ __html: icon }}
                />
            ) : (
                // Если icon — это имя иконки, используем компонент Iconify
                <Iconify icon={icon} width={iconSize} height={iconSize} />
            )}

        </Box>
    )

    const textOl = textForOl?.length > 0

    let typographyVariant = "h5"

    if (iconSize > 100) {
        typographyVariant = "h5"
    }
    if (iconSize > 120) {
        typographyVariant = "body1"
    }
    if (iconSize > 148) {
        typographyVariant = "body2"
    }

    const renderContent = (
        <Stack
            spacing={1}
            sx={{
                flex: 1,
                maxWidth: `calc(100% - ${iconSize}px)`,
            }}
        >
            <Stack spacing={1}
                sx={{
                    mt: label ? 0 : 1
                }}
            >
                {label && blockLabel}
                <Typography
                    variant={typographyVariant}
                    fontWeight={700}
                    sx={{ color: usePikerColor ? textColor : calculatedTextColor, opacity: textOpacity/100}}
                >
                    {title}
                </Typography>
                <Typography variant="caption" sx={{ color: usePikerColor ? textColor : calculatedTextColor, fontSize: 14, lineHeight: 1.2 }}>
                    {text}
                </Typography>
            </Stack>
            {showBtn &&
                // <Button variant="contained" color="warning" onClick={() => buttonClick()}>{btnText}</Button>
                <ArticleMarkdownButton text={btnText} link={btnLink} tgUser={tgWebApp?.initDataUnsafe?.user || TgNullObj} banner 
                    sx={{
                        '& .MuiButton-root': {
                        backgroundColor: buttonStyle?.useBackgroundButtonColor
                        ? buttonStyle?.backgroundColor : "transportal", 
                        // color: buttonStyle?.textColor,     
                        color: buttonStyle?.useTextButtonColor
                        ? buttonStyle?.textColor
                        : "#000000",     
                        },
                    }}
                />
            }
        </Stack >
    )

    let justifyContentValue
    if (iconPosition === 'start') {
        justifyContentValue = 'end'
    } else {
        justifyContentValue = 'start'
    }
    console.log('imagine',imagine)

    return (
        <Box 
            {...other} 
            sx={sx} 
        >
            <Card
                sx={{
                    overflow: 'visible',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: justifyContentValue,
                    // justifyContent: iconPosition === 'start' ? (iconOutside ? 'start' : 'space-between') : (iconOutside ? 'end' : 'space-between'),
                    ...(variant === 'grd90' && {
                        boxShadow: 'none',
                    }),
                    ...(variant === 'ghost' && {
                        borderWidth: 1,
                        borderStyle: 'dashed',
                        borderColor: theme.palette.divider,
                    }),
                    // mt: iconOutside ? 2 : 0,

                    // mt: marginTopValue,
                    // mb: marginTopValue,

                    py: showBtn ? 2 : 0.5,
                    px: 2,
                    ...(!showBtn && {
                        pb: 2
                    }),

                    // pl: 2,
                    color,
                    position: 'relative',
                    // ...bgGradient({
                    //     direction: `${ typeof gradientDeg === 'string'
                    //         ? gradientDeg.replace(/[^0-9.]/g, '') 
                    //         : gradientDeg || 120}deg`,
                    //     startColor,
                    //     endColor,
                    // }),
                    // ...(useImagine && {
                    //     background: `url(${imagine})`,
                    //     backgroundSize: 'cover', 
                    //     backgroundRepeat: 'no-repeat',
                    //     // backgroundPosition: 'center',
                    // }),

                    // eslint-disable-next-line no-nested-ternary
                    backgroundImage: useImagine
                    ? `url(${imagine}), linear-gradient(${typeof gradientDeg === 'string'
                        ? gradientDeg.replace(/[^0-9.]/g, '')
                        : gradientDeg || 120}deg, ${startColor}, ${endColor})`
                    : useBackgroundColor
                        ? `linear-gradient(${typeof gradientDeg === 'string'
                            ? gradientDeg.replace(/[^0-9.]/g, '')
                            : gradientDeg || 120}deg, ${startColor}, ${endColor})`
        
                        : 'none',
                        
                    backgroundSize: useImagine ? 'cover' : undefined,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center', 

                    // eslint-disable-next-line object-shorthand
                    borderRadius: borderRadius,

                }}
                {...other}
            >

                {iconPosition === 'start' &&
                    <>
                        {icon !== null && renderIcon}
                        {renderContent}
                    </>
                }

                {iconPosition === 'end' &&
                    <>
                        {renderContent}
                        {icon !== null && renderIcon}
                    </>
                }



            </Card>
        </Box>
    )
}


const hexToRgb = (hex) => {
	// console.log('hexhex', hex)
	if (!hex || typeof hex !== "string") return "0, 0, 0"

	let r = 0; let g = 0; let b = 0
	if (hex.length === 4) {
		r = parseInt(hex[1] + hex[1], 16)
		g = parseInt(hex[2] + hex[2], 16)
		b = parseInt(hex[3] + hex[3], 16)
	} else if (hex.length === 7) {
		r = parseInt(hex[1] + hex[2], 16)
		g = parseInt(hex[3] + hex[4], 16)
		b = parseInt(hex[5] + hex[6], 16)
	}
	return `${r}, ${g}, ${b}`
}
