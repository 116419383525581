import PropTypes from "prop-types";
import Iframe from 'react-iframe'

import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {useEffect, useRef} from "react";
import EmptyContent from "../empty-content";
import {useEventListener} from "../../hooks/use-event-listener";

VkPlayer.propTypes = {
    url: PropTypes.string ,
    disabled: PropTypes.bool ,
    responsive: PropTypes.bool ,
    onClickPreview: PropTypes.func ,
    type: PropTypes.string ,
}

export const StyledDivLayer = styled('div')(() => ({
    position: "absolute" ,
    // paddingBottom: "56.25%" , /* Player ratio: 100 / (1280 / 720) */
    height: '100%' ,
    overflow: 'hidden',
    backgroundColor: 'black' ,
}));

export default function VkPlayer({url , disabled , responsive , onClickPreview , type='vk'}){
    const _url = url.replaceAll('&amp;' , '&');
    const handleContextMenu = (event) => {
        event.preventDefault();
    }
    const handleKeyDown = (event) => {

        if (event.key === 123 && event.metaKey) {
            return false;
        }
        // if (event.key === 'Control' && event.key === 'Shift' && event.code === 'KeyI') {
        //     return false;
        // }
        // if (event.key === 'Control' && event.key === 'Shift' && event.code === 'KeyJ') {
        //     return false;
        // }
        // if (event.key === 'Control' && event.key === 'Shift' && event.code === 'KeyJ') {
        //     return false;
        // }
        // if (event.key === 'Control' && event.code === 'KeyC') {
        //     return false;
        // }

        return false;
    };
    useEventListener('keydown', handleKeyDown);
    useEventListener('contextmenu', handleContextMenu);
    const renderEmpty = (
        <EmptyContent
            filled
            title="Видео не доступно"

        />
    );

    const sx = {
        ...(responsive && {
            position: "relative" ,
            paddingBottom: "56.25%" ,
            height: 0 ,
            overflow: 'hidden' }),
         }

         const sx_vertical = {
             position: "relative" ,
             overflow: 'hidden'
         }

    return (
        <>
            {_url &&
            <Box sx={type === 'vkclip' ? sx_vertical : sx}
            >

                <RenderIframePlayer url={_url} type={type} />
                {disabled &&
                    <Box onClick={onClickPreview}
                         sx={{position: "absolute",  top: 0 , left: 0 , width: "100%" , height: "100%" , zIndex: 1000 , opacity: 0.48, filter: 'grayscale(1)' , backgroundColor: 'white'}}
                         onContextMenu={(e)=> console.log("rkbrb")}
                    />
                }
            </Box>
            }
            {!url && renderEmpty}
        </>
    );
}

RenderIframePlayer.propTypes = {
    url: PropTypes.string ,
    type:  PropTypes.oneOf(['vk', 'rutube' , 'dzen' , 'ya']) ,
}

function RenderIframePlayer ({url , type}) {
    // https://rutube.ru/play/embed/d351a42bd0a0bba0d8ca00b58bebd267/"

    const sx = {position: "absolute" ,
        top: 0 ,
        left: 0 ,
        width: "100%" ,
        height: "100%" ,
        borderWidth: 0 ,
        outlineWidth: 0} ;
    const rutube = (
        <Iframe
            src={url}
            frameBorder="0" allow="clipboard-write; autoplay"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
            styles={sx}
        />
    );

    const vk = (
        <Iframe src={url}  allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameBorder="0" allowFullScreen
                width="100%"
                height="auto"
                styles={sx}
        />
    );

    const vkclip = (
        <Iframe src={url} allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameBorder="0" allowFullScreen
                // width="325"
                width="100%"
                height="646"
        />
    );

    const dzen = (
        <Iframe src={url}
                allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media"
                frameBorder="0"
                scrolling="no"
                allowFullScreen
                width="100%"
                height="auto"
                styles={sx}
        />
    );

    const yavideo = (
        <Iframe src={url}
                allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media"
                frameBorder="0"
                scrolling="no"
                width="100%"
                height="auto"
                styles={sx}
        />

    );

    const plvideo = (
        <Iframe src={url}
                allow='autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media' frameborder='0' scrolling='no'
                width="100%"
                height="auto"
                styles={sx}
        />

    );

    return(
        <>
            {type === 'vk' && vk}
            {type === 'vkclip' && vkclip}
            {type === 'rutube' && rutube}
            {type === 'dzen' && dzen}
            {type === 'ya' && yavideo}
            {type === 'plvideo' && plvideo}
        </>
    )
}
