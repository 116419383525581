import * as React from 'react'
import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { grey } from '@mui/material/colors'
import { styled, alpha } from '@mui/material/styles'
import { useEffect, useMemo, useState } from "react"
import Iconify from "../../components/iconify"
import { useLocales } from "../../locales"
import { paths } from "../../routes/paths"
import { useSelector } from "../../redux/store"
// import { useRouter } from "../../routes/hooks"
import { useParams, useRouter } from "../../routes/hooks"
// import { palette } from "../../theme/palette"
// import { shadows } from "../../theme/shadows"
// import { customShadows } from "../../theme/custom-shadows"
// import { typography } from "../../theme/typography"

const BottomNA = styled(BottomNavigationAction)(({ theme }) => ({
    // border: `1px solid ${theme.palette.primary.dark}`,
    // borderRadius: "16px" ,
    // borderRadius: 1,
    // backgroundColor: theme.palette.primary.light,
    // backgroundColor: grey[200],
    padding: 0,
    minWidth: "75px",
    '& .MuiBottomNavigationAction-label': {
        color: theme.palette.text.disabled,
        fontSize: '0.75rem',
        transition: 'none',
    },
    '& .MuiSvgIcon-root': {
        fontSize: '28px',
        transition: 'none',
    },
    '&.Mui-selected .MuiBottomNavigationAction-label': {
        color: theme.palette.primary.main,
        fontSize: '0.75rem',
    },
    '&.Mui-selected .MuiSvgIcon-root': {
        fontSize: '28px',
    },
    userSelect: 'none',
}))

const ScrollContainer = styled(Box)({
    display: 'flex',
    overflowX: 'auto',
    // width: 'calc(5.2 * 100px)',
    // alignItems: 'flex-start',
    userSelect: 'none',
    cursor: 'grab',
    // Отображение скролбара
    whiteSpace: 'nowrap',
    scrollbarWidth: 'thin',
    scrollbarColor: `${grey[400]} ${grey[200]}`,
    '&::-webkit-scrollbar': {
        width: '12px',
        height: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: grey[400],
        borderRadius: '6px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: grey[200],
        borderRadius: '6px',
    },

    // Скрытие скролбара
    // '&::-webkit-scrollbar': {
    //     display: 'none',
    // },
    // '-ms-overflow-style': 'none',
    // 'scrollbar-width': 'none',
})

const CustomBottomNavigation = styled(BottomNavigation)({
    // justifyContent: 'flex-start',
    justifyContent: 'space-between',
    width: '100%' ,
    backgroundColor: grey[200],
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    userSelect: 'none',
})

export default function DashboardBottomNavigation() {
    const [value, setValue] = useState(0)
    const { t } = useLocales()
    const router = useRouter()
    const { curShopId } = useSelector((state) => state.startform)
    // console.log("cur shop id", curShopId)
    const curLocation = router.location()

    const arPath = useMemo(
        () => ([
            paths.dashboard.shop(curShopId),
            paths.dashboard.general.shop(curShopId),
            paths.dashboard.general.usercard(curShopId) ,
            // paths.dashboard.usercard.root(curShopId),
            paths.dashboard.general.articles(curShopId),
            paths.dashboard.general.chatbot(curShopId),
            // paths.dashboard.general.tools(curShopId),
            // paths.dashboard.general.settings(curShopId),
        ]),
        [curShopId]
    )
    // const arPath = [
    //     paths.dashboard.general.shop(curShopId),
    //     // paths.dashboard.general.usercard(curShopId) ,
    //     paths.dashboard.usercard.root(curShopId),
    //     paths.dashboard.general.articles(curShopId),
    //     paths.dashboard.general.chatbot(curShopId),
    //     paths.dashboard.general.tools(curShopId),
    //     paths.dashboard.general.settings(curShopId),
    // ]
    const curIndexPath = arPath.findIndex((el, index) => el.includes(curLocation?.pathname))
    if (curIndexPath > -1 && value !== curIndexPath) {
        setValue(curIndexPath)
    }

    // useEffect(() => {
    //     const curIndexPath = arPath.findIndex((el , index) => el.includes(curLocation?.pathname) )
    //     setValue(curIndexPath)
    //
    // }, [arPath, curLocation]);

    const handleMouseDown = (event) => {
        const scrollContainer = event.target.closest('[data-scroll-container]')
        if (scrollContainer) {
            scrollContainer.style.cursor = 'grabbing'
            const startX = event.pageX
            const {scrollLeft} = scrollContainer

            const handleMouseMove = (moveEvent) => {
                const x = moveEvent.pageX - startX
                scrollContainer.scrollLeft = scrollLeft - x
            }

            const handleMouseUp = () => {
                scrollContainer.style.cursor = 'grab'
                window.removeEventListener('mousemove', handleMouseMove)
                window.removeEventListener('mouseup', handleMouseUp)
            }

            window.addEventListener('mousemove', handleMouseMove)
            window.addEventListener('mouseup', handleMouseUp)
        }
    }


    return (
        <Box>
            <ScrollContainer data-scroll-container onMouseDown={handleMouseDown}>

                <CustomBottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue)
                        router.push(arPath[newValue])
                    }}

                >
                    <BottomNA label={t(`dashboard`)} icon={<Iconify icon="solar:home-bold" width={24} />} />
                    <BottomNA label={t(`shop._`)} icon={<Iconify icon="mdi:shopping" width={24} />} />
                    <BottomNA label={t(`payment.usercard`)} icon={<Iconify icon="mdi:clipboard-person" width={24} />} />
                    <BottomNA label={t(`pages.self`)} icon={<Iconify icon="mdi:book-open-page-variant" width={24} />} />
                    <BottomNA label={t(`chat bot`)} icon={<Iconify icon="mdi:message-text-clock" width={24} />} />
                    {/* <BottomNA label={t(`tools`)} icon={<Iconify icon="mdi:tools" width={24} />} /> */}
                    {/* <BottomNA label={t(`system.settings`)} icon={<Iconify icon="mdi:settings" width={24} />} /> */}

                </CustomBottomNavigation>
            </ScrollContainer>
        </Box>
    )
}
