import React, { useEffect, useState, useRef, Suspense } from 'react'
import PropTypes from 'prop-types';
// import Stories from 'react-insta-stories'
import {Modal, Box, IconButton, Stack, Button, CircularProgress} from '@mui/material'
import Iconify from 'src/components/iconify'
import Scrollbar from 'src/components/scrollbar'
import {useTheme} from "@mui/material/styles";
import { storyData } from '../../_mock/_stories2'
import {useSettingsContext} from "../../components/settings";
import Markdown from "../../components/markdown";
import {ArticleMarkdownButton} from "../article/articles/article-markdown-button";
import {TG_NULL_OBJECT} from "../../config-global";
import {StoriesSkeleton} from "./skeleton/stories-skeleton";
import {useSelector} from "../../redux/store";
import {isTgLink, selfLink} from "../../utils/article-self-link";
import {useRouter} from "../../routes/hooks";

const StoriesLazy = React.lazy(() => import("react-insta-stories"))
const TgNullObj = process.env.NODE_ENV !== 'production' ? TG_NULL_OBJECT : null

const StoriesComponent = ({ handleUpdateFormat, onClose, type , storyList , stepStories , showStories , showLastButton = false , showCarousel = false , textLastSlideBtn = "Перейти на новую версию"  , loading = false}) => {

    const {tgWebApp} = useSettingsContext()
    const router = useRouter()
    const scrollRef = useRef(null)
    const [open, setOpen] = useState(true)
    const [currentStories, setCurrentStories] = useState([])
    const [isPaused, setIsPaused] = useState(false)
    const [hasReachedEnd, setHasReachedEnd] = useState(false)
    const touchTimeout = useRef(null)
    const [step , setStep] = useState(0);
    const theme = useTheme();
    const { curShopId } = useSelector((state) => state.startform)

    const storyDataItems = storyList || storyData

    const defHeaderColor = theme.palette.background.default; // tgWebApp.headerColor;
    const hex = currentStories[step]?.backgroundColor;

    // смена шапки в приложении ТГ


    useEffect(() => {
        if(tgWebApp && tgWebApp?.isVersionAtLeast('6.1')){
            if(currentStories.length > 0 && open){
                tgWebApp.setHeaderColor(hex || defHeaderColor);
            }else{
                tgWebApp.setHeaderColor(defHeaderColor)
            }
            // if(!open) tgWebApp.setHeaderColor(defHeaderColor)
        }
    } ,[currentStories.length, defHeaderColor, hex, open, tgWebApp])
    // if(currentStories.length > 0){
    //         if(tgWebApp){
    //             // if(stepStories <= hex.length - 1 && stepStories > -1)hexColor=hex[stepStories]
    //
    //             tgWebApp.setHeaderColor(hex);
    //         }
    //     }
    //     else if(tgWebApp) {
    //             tgWebApp.setHeaderColor(defHeaderColor)
    //     }

    useEffect(() => {
        if (type) {
            const selectedStories = storyDataItems.filter(story => story.type === type) || [];
            setCurrentStories(selectedStories[0].slides);
            setOpen(true);
        }else if(!type && storyList?.slides){
            setCurrentStories(storyList?.slides);
            setOpen(true);
        }
    }, [storyDataItems, storyList?.slides, type]);

    const handleOpen = (index) => {
        const selectedStories = storyData[index]?.slides || [];
        setCurrentStories(selectedStories);
        setOpen(true);
    };

    const handleClose = () => {
        onClose();
        setOpen(false);
        setCurrentStories([]);
        clearTimeout(touchTimeout.current);

    };

    const handlePause = (event) => {
        event.preventDefault()
        touchTimeout.current = setTimeout(() => {
            setIsPaused(true)
        }, 200)
    }

    const handleResume = (event) => {
        event.preventDefault()
        clearTimeout(touchTimeout.current)
        setIsPaused(false)
    }


    const handleButtonClick = () => {
        handleUpdateFormat();
        handleClose();
    };

    const handleBtnClick = (link) => {

        const lnk = selfLink(link , curShopId);
        handleClose();
        if(lnk?.type==='router'){
            router.push(lnk?.link);
        }else if(lnk?.type === 'location'){
            if(isTgLink(lnk?.link)) tgWebApp.openTelegramLink(lnk?.link);
            else window.location.href = lnk?.link;
        }

    }

    const renderCarousel = (
        <Scrollbar
            sx={{
                '& .simplebar-track': { display: 'none' },
                '& .simplebar-scrollbar': { display: 'none' },
                cursor: 'grab',
                userSelect: 'none',
            }}
        >
            <Stack
                direction="row"
                ref={scrollRef}
                sx={{
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': { display: 'none' },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    cursor: 'grab',
                }}
                onMouseDown={(event) => {
                    const startX = event.pageX
                    const {scrollLeft} = scrollRef.current

                    const onMouseMove = (moveEvent) => {
                        const x = moveEvent.pageX - startX
                        scrollRef.current.scrollLeft = scrollLeft - x
                    }

                    const onMouseUp = () => {
                        document.removeEventListener('mousemove', onMouseMove)
                        document.removeEventListener('mouseup', onMouseUp)
                    }

                    document.addEventListener('mousemove', onMouseMove)
                    document.addEventListener('mouseup', onMouseUp)
                }}
            >
                {storyData.map((storySet, index) => (
                    <IconButton key={index} onClick={() => handleOpen(index)}>
                        <img
                            src={storySet.slides[0]?.url}
                            alt={`Story Icon ${index + 1}`}
                            width={64}
                            height={64}
                            style={{ borderRadius: '15px', border: '2px solid #ddd' }}
                        />
                    </IconButton>
                ))}
            </Stack>
        </Scrollbar>
    )


    const renderContent = (slide, index) => {
        const isLastSlide = index === currentStories.length - 1;

        // console.log('slide',slide)
        return (
            <div
                style={{
                    display: 'flex',
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    background:  slide?.backgroundColor,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                role="button"
                tabIndex="0"
                onMouseDown={handlePause}
                onMouseUp={handleResume}
                onTouchStart={handlePause}
                onTouchEnd={handleResume}

                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        setIsPaused(true);
                    }
                }}
                onKeyUp={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        setIsPaused(false);
                    }
                }}
            >
                {slide.type === 'video' && !slide.url.endsWith('.gif') ? (
                    <video
                        src={slide?.url}
                        alt={slide?.alt}
                        style={{
                            position: 'absolute',
                            width: "100%",
                            height: "100%",
                            // objectFit: "cover",
                            objectFit: "contain",
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                        controls
                        autoPlay
                        loop
                    >
                        <track kind="captions" srcLang="en" label="English captions" default />
                    </video>
                ) : (
                  <>
                      {slide?.url &&
                          <img
                              src={slide?.url}
                              alt={slide?.alt}
                              style={{
                                  position: 'absolute',

                                  // height: "100%",
                                  height: "100%",

                                  // objectFit: "cover",
                                  objectFit: "contain",
                                  top: 0,
                                  left: 0,
                                  zIndex: 1,
                              }}
                          />
                    }
                  </>

                )}


                {slide?.overlays && slide?.overlays.map((overlay, idx) => (
                    <>
                    {overlay?.type === 'text2' &&
                        (<div
                            key={idx}
                            style={{
                                position: 'absolute',
                                top: overlay.styles.top,
                                left: overlay.styles.left,
                                fontSize: overlay.styles.fontSize,
                                color: overlay.styles.color,
                                whiteSpace: 'pre-line'  ,
                            }}
                        >
                            {overlay?.value}
                        </div>
                        )
                    }
                    {overlay?.type === 'text' && (
                        <Markdown children={overlay?.value} sx={{
                            position: 'absolute',
                            top: overlay?.styles?.position?.y,
                            left: overlay?.styles?.position?.x,
                            // right: overlay?.styles?.right,
                            fontSize: overlay?.styles?.fontSize,
                            color: overlay?.styles?.color,
                            whiteSpace: 'pre-line'  ,
                            px:1
                        }}/>
                    )}
                    {overlay?.type === 'button' && (
                        <Button
                            variant="contained"
                            color="warning"
                            size="large"
                            onClick={() => handleBtnClick(overlay?.link)}
                            onTouchEnd={() => handleBtnClick(overlay?.link)}
                            style={{
                                position: 'absolute',
                                bottom: 20,
                                left: '50%',
                                width: "90%",
                                mx:1,
                                transform: 'translateX(-50%)',
                                zIndex: 1001,
                            }}
                        >
                            {overlay?.text}
                        </Button>
                        // <ArticleMarkdownButton text={overlay?.text} link={overlay?.link}
                        //                        tgUser={tgWebApp?.initDataUnsafe?.user || TgNullObj}
                        //                        stories
                        //                        size="large"
                        //                        color="info"
                        //                        fullWidth
                        //                        sx={{
                        //                            position: 'absolute',
                        //                            bottom: 20,
                        //                            left: '50%',
                        //                            width: "90%",
                        //                            // mx:1,
                        //                            transform: 'translateX(-50%)',
                        //                            zIndex: 1001,
                        //                        }}
                        // />
                    )}
                    </>
                    )
                )}

                {(isLastSlide && showLastButton) && (
                    <Button
                        variant="contained"
                        color="warning"
                        size="large"
                        onClick={handleButtonClick}
                        onTouchEnd={handleButtonClick}
                        style={{
                            position: 'absolute',
                            bottom: 20,
                            left: '50%',
                            width: "90%",
                            mx:1,
                            transform: 'translateX(-50%)',
                            zIndex: 1001,
                        }}
                    >
                        {textLastSlideBtn}
                    </Button>
                )}
            </div>
        )
    }

    const handleSetStep = (s, st) => {
        setStep(s);
    }

    return (
        <Box>

            {showCarousel && renderCarousel}

            <Modal
                open={open}
                onClose={handleClose}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={{ position: 'relative', width: '100vw', height: '100%',  }}>
                    <IconButton
                        sx={{ position: 'absolute', top: 16, right: 16, zIndex: 1000  , filter: 'contrast(200%)' }}
                        onClick={handleClose}
                    >
                        <Iconify icon="mingcute:close-fill"  />
                    </IconButton>
                    {loading && <StoriesSkeleton />}
                    {(currentStories.length > 0  && !loading) && (
                        <Suspense fallback={<CircularProgress/>}>
                            <StoriesLazy

                                stories={currentStories.map((slide, index) => ({
                                    content: () => renderContent(slide, index)
                                }))}

                                preloadCount={3}
                                loop={false}
                                defaultInterval={5000}
                                width="100%"
                                height="100%"

                                storyContainerStyles={{ overflow: "hidden" }}
                                loader={<CircularProgress/>}

                                progressStyles={{
                                    // background: '#232325',
                                    height: '4px' ,
                                    borderRadius: '50px'
                                }}

                                progressWrapperStyles={{
                                    height: '4px' ,
                                    borderRadius: '50px' ,
                                    filter: 'opacity(55%)'
                                }}

                                progressContainerStyles={{
                                    filter:'contrast(200%)' ,
                                }}

                                isPaused={hasReachedEnd || isPaused}
                                onStoryEnd={(storyIndex, stories) => {
                                    if (storyIndex === stories.length - 1) {
                                        // If last slide, pause instead of closing
                                        setHasReachedEnd(true)
                                        setIsPaused(true)
                                    }
                                }}
                                // onStoryStart={stepStories}
                                onStoryStart={handleSetStep}
                            />
                        </Suspense>
                    )}
                </Box>
            </Modal>
        </Box>
    )
}

StoriesComponent.propTypes = {
    handleUpdateFormat: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    stepStories: PropTypes.func ,
    showCarousel: PropTypes.bool ,
    textLastSlideBtn: PropTypes.string ,
    showStories: PropTypes.bool ,
    storyList: PropTypes.object ,
    showLastButton: PropTypes.object ,
    loading: PropTypes.bool ,

}

export default StoriesComponent
