import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices

import productReducer from './slices/product';
import startformReducer from './slices/startform';
import shopsReducer from './slices/shops';
import lpReducer from './slices/lp';
import userReducer from './slices/user';
import uploadysReducer from './slices/uploadys';
import notifyReducer from './slices/notify';
import subscribersReducer from './slices/subscribers';
import referalsReducer from './slices/referals';
import usercardReducer from "./slices/usercard";
import formsReducer from "./slices/forms";
import quizzesReducer from "./slices/quizzes";
import analyticsReducer from "./slices/analytics";
import leaderboardReducer from "./slices/leaderboard";
import referralGameReducer from "./slices/referralGame";
import analyticsGameReducer from "./slices/analyticsGame";
import adminReducer from "./slices/admin";
import settingsPaymentReducer from "./slices/settingsPayment";
import promotionsReducer from "./slices/promotions"
import ordersReducer from "./slices/orders"
import storiesReducer from "./slices/stories"

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({

  product: persistReducer(productPersistConfig, productReducer),
  startform: startformReducer ,
  shops : shopsReducer ,
  lp : lpReducer ,
  user : userReducer ,
  uploadys: uploadysReducer ,
  notify: notifyReducer ,
  subscribers: subscribersReducer ,
  referals: referalsReducer ,
  usercard: usercardReducer ,
  forms: formsReducer ,
  quizzes: quizzesReducer ,
  analytics: analyticsReducer ,
  leaderboard: leaderboardReducer ,
  referralGame: referralGameReducer ,
  analyticsGame: analyticsGameReducer,
  admin: adminReducer ,
  settingsPayment: settingsPaymentReducer ,
  promotions: promotionsReducer ,
  orders: ordersReducer ,
  stories: storiesReducer ,


});

export default rootReducer;
