 import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import FormProvider, {
    RHFSelect,
    RHFSwitch,
    RHFTextField,
    RHFMultiSelect,
    RHFMultiCheckbox, RHFRadioGroup,
} from 'src/components/hook-form';
import React, {useCallback, useEffect, useMemo, useState} from "react";

import * as Yup from "yup";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/ru';

import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import Alert from "@mui/material/Alert";
import "yup-phone";
 import Checkbox from "@mui/material/Checkbox";
 import FormControlLabel, {formControlLabelClasses} from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
 import Button from "@mui/material/Button";
 import {LoadingButton} from "@mui/lab";
 import { parsePhoneNumberFromString } from 'libphonenumber-js';
import ComponentBlock from "./component-block";

import {useRouter} from "../../routes/hooks";
import {useLocales} from "../../locales";
import axios from "../../utils/axios";
import {useDispatch, useSelector} from "../../redux/store";
import {useSnackbar} from "../snackbar";

import Iconify from "../iconify";

import Markdown from "../markdown";
 import {fDate} from "../../utils/format-time";
 import RHFPhoneTextfield from "../hook-form/rhf-phone-textfield";




export default function ArticleForm({form , tgID , tgUser , onComplete , borderType,
                                        backgroundColor , backgroundOpacity}){
    const router = useRouter();
    const {t} = useLocales();

    const formFields = form.fields;
    const {  curShopId  } = useSelector((state) => state.startform);

    const { enqueueSnackbar } = useSnackbar();

    const [locale, setLocale] = useState('ru');
    const [loading , setLoading] = useState(false);
    const [complete , setComplete] = useState(form?.complete || false);
    const [phone , setPhone] = useState('');
    const [checkedPolicy, setCheckedPolicy] = useState(true);

    const handleChangePolicy = (event) => {
        setCheckedPolicy(event.target.checked);
    };

    // const complete = form?.complete || false ;
    let infoForm = {};

    formFields?.questions.forEach((el , index) => {
         const retObj = {};
         if(el.type === 'list of list') infoForm = { ...infoForm ,[`checkbox_${index}`] : { title: el.title , items: el?.options , } };
         if(el.type === 'one of list') infoForm = { ...infoForm ,[`radio_${index}`] : { title: el.title , items: el?.options }  };
         if(el.type === 'text') infoForm = { ...infoForm,[`text_${index}`] : { title: el.title , items: el?.options }  };
          if(el.type === 'phone') infoForm = { ...infoForm,[`phone_${index}`] : { title: el.title , items: el?.options }  };
          if(el.type === 'email') infoForm = { ...infoForm,[`email_${index}`] : { title: el.title , items: el?.options }  };
          if(el.type === 'verny_otvet') infoForm = { ...infoForm,[`qa_${index}`] : { title: el.title , items: el?.options }  };
         if(el.type === 'datetime') infoForm = { ...infoForm,[`date_${index}`] : { title: el.title , items: el?.options , type: el?.type , category: el?.category }  };
    })


    // console.log("DEFF" , infoForm);

    const setDefault = useCallback((act = 'default') => {
        let def = {};
        let yupSchema = {};
        formFields?.questions.forEach((el , index) => {
            const retObj = {};

            if(el.type === 'list of list'){
                def = { ...def,[`checkbox_${index}`] : [] };
                if(el.required){
                    yupSchema = {...yupSchema , [`checkbox_${index}`] : Yup.array().min(1, `${t('form.required')}`) }
                }
            }
            if(el.type === 'one of list'){
                def = { ...def,[`radio_${index}`] : '' };
                if(el.required) {
                    yupSchema = {...yupSchema, [`radio_${index}`]: Yup.string().required(`${t('form.required')}`)};
                }
            }
            if(el.type === 'text'){
                def = { ...def,[`text_${index}`] : '' };
                if(el.required){
                  yupSchema = {...yupSchema , [`text_${index}`] : Yup.string().required(`${t('form.required')}`)};
                }
            }
            // if(el.type === 'phone'){
            //     def = { ...def,[`phone_${index}`] : '' };
            //     const phoneRegExp =/^(?<code>\+?\d{1,3})[-\s]{0,}(?<number>\(?\d{3}\)?[-\s]{0,}\d{3}[-\s]{0,}\d{2}[-\s]{0,}\d{2})$/gm;
            //        if(!el.required){
            //         yupSchema = {...yupSchema , [`phone_${index}`] : Yup.string().matches(phoneRegExp, `${t('form.invalid_phone_format')}`).required(`${t('form.required')}`)};
            //        }
            // }
            if (el.type === 'phone') {
                // Добавляем поле в объект значений по умолчанию
                def = { ...def, [`phone_${index}`]: '' };

                // Создаём схему валидации для поля с использованием libphonenumber-js
                const phoneValidation = Yup.string()
                    .required(t('form.required'))
                    .transform((value) =>
                        // Удаляем все символы, кроме цифр и +
                         value.replace(/[^\d+]/g, '')
                    )
                    .test('is-valid-phone', t('form.invalid_phone_format'), (value) => {
                        if (!value) return false;

                        // Парсим номер телефона
                        const phoneNumber = parsePhoneNumberFromString(value, 'RU');
                        return phoneNumber?.isValid() || false;
                    });

                // Добавляем валидацию в схему
                yupSchema = {
                    ...yupSchema,
                    [`phone_${index}`]: el.required ? phoneValidation : phoneValidation.notRequired(),
                };
            }
            if(el.type === 'email'){
                def = { ...def,[`email_${index}`] : '' };
                const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                if(!el.required){
                  // yup.string().email().matches(/^(?!.*@[^,]*,)/)
                    yupSchema = {...yupSchema , [`email_${index}`] : Yup.string().trim().required(`${t('form.required')}`).email(`${t('form.invalid_email_format')}`).matches(emailRegex, `${t('form.invalid_email_format')}`)};
                }
            }
            // ^(\+)?((\d{2,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){5,12}\d$
            // verny_otvet
            if(el.type === 'verny_otvet'){
                def = { ...def,[`qa_${index}`] : '' };

                if(el?.options){
                    const regex = `/(${el?.options})/i`
                    let arSearch = []
                    if(typeof el?.options === "string")arSearch = [el?.options.toLowerCase()]
                    else if(Array.isArray(el?.options))arSearch = el?.options?.map(str => str.toLowerCase())
                    // const arSearch = typeof el?.options === "string" ? [el?.options.toLowerCase()] : el?.options?.map(str => str.toLowerCase());
                    yupSchema = {...yupSchema , [`qa_${index}`] : Yup.string().lowercase().oneOf(arSearch , `${t('form.incorrect_answer')}`)};
                }
            }
        })
        if(act === 'yup')return yupSchema;
        return def;

    } , [formFields?.questions, t])


    const defaultValues = useMemo(
        () => setDefault(),
        [setDefault]
    );

    const newSchema = Yup.object().shape(setDefault('yup'));
    const methods = useForm({
        resolver: yupResolver(newSchema),
        defaultValues
    });

    const {
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;
    const values = watch();


    useEffect(() => {
        // Получаем все ключи, начинающиеся на "qa_"
        const qaKeys = Object.keys(values).filter(key => key.startsWith('qa_'));

    } , [values])

    const onSubmit = handleSubmit(async (data) => {
      // console.log("DATA" , data);
        // if(policy && !checkedPolicy)

        let retObj = [];

        formFields.questions.forEach((el , index) => {

            if(el.type === 'list of list' && data[`checkbox_${index}`] !== undefined) {
              const itms = infoForm[`checkbox_${index}`].items;

              const push = itms.filter((elms , ind) => ind in data[`checkbox_${index}`])
                // retObj = {...retObj , [`checkbox_${index}`]: push  }
                retObj = [...retObj ,
                      {
                        title: infoForm[`checkbox_${index}`].title,
                        answers: push ,

                      }
                  ]
            }else if(el.type === 'one of list' && data[`radio_${index}`] !== undefined) {
                const radioItms = infoForm[`radio_${index}`].items;

                const indexItem = Number(data[`radio_${index}`]);
                // retObj = {...retObj , [`radio_${index}`]:  data[`radio_${index}`] === '' ? '': radioItms[indexItem]  }
                retObj = [...retObj ,
                    {
                        title: infoForm[`radio_${index}`].title,
                        answers: [data[`radio_${index}`] === '' ? '': radioItms[indexItem]]
                    }
                ]
            }else  if(el.type === 'text'){
                retObj = [...retObj ,
                    {
                        title: infoForm[`text_${index}`].title,
                        answers: [data[`text_${index}`]]
                    }
                ]
            }else  if(el.type === 'verny_otvet'){
                retObj = [...retObj ,
                    {
                        title: infoForm[`qa_${index}`].title,
                        answers: [data[`qa_${index}`]]
                    }
                ]
                // retObj = {...retObj , [`text_${index}`]: data[`text_${index}`] }
            }else  if(el.type === 'datetime'){
                retObj = [...retObj ,
                    {
                        title: infoForm[`date_${index}`].title,
                        answers: fDate(data[`date_${index}`] , 'yyyy-MM-dd') ,
                        type: infoForm[`date_${index}`].type,
                        category: infoForm[`date_${index}`].category,
                    }
                ]
            }
            else  if(el.type === 'phone'){
                retObj = [...retObj ,
                    {
                        title: infoForm[`phone_${index}`].title,
                        answers: [data[`phone_${index}`]]
                    }
                ]
            }
            else  if(el.type === 'email'){
                retObj = [...retObj ,
                    {
                        title: infoForm[`email_${index}`].title,
                        answers: [data[`email_${index}`]]
                    }
                ]
            }

            // if(el.type === 'one of list') def = { ...def,[`radio_${index}`] : '' };

        })
        // console.log("DATA OBJ" , retObj);
        const fname = tgUser?.first_name || "-";
        const lname = tgUser?.last_name || "-";

        const postData = {
                              answers: retObj ,
                              tg_id: tgID ,
                              username: `${fname} ${lname}`,
                              tglogin: tgUser?.username||"@",
                              avatar: tgUser?.photo_url||"",
        }

        try {
            setLoading(true);
            const response = await axios.post(`/forms/${curShopId}/${form.id}/fillings`, postData );
            console.log("response" , response);

            if(response.data.success){
                onComplete({show: true , formId: form.id , points: form?.points_for_filling || 0})
                enqueueSnackbar(t('system.create_success'));
                setComplete(true);

            }else{
                enqueueSnackbar(t('system.create_fail') , { variant: 'error' });
            }

            reset();
            setLoading(false);
            // enqueueSnackbar( 'Create success!');
            // router.push(paths.dashboard.product.root);
            // console.log('DATA', data);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    });


    const renderTextField = (title , index) => (

            <Stack spacing={1}>
                <Divider sx={{ border : "1px dashed" }}/>
                <Stack direction="row" sx={{pt:1}} alignItems="center">
                    <Iconify icon="iconamoon:arrow-right-2-bold" />
                    <Typography variant="body2">{title}</Typography>
                </Stack>
                <RHFTextField
                    name={`text_${index}`}
                    label="Ответ"
                    fullWidth
                    variant="filled"
                    multiline
                />
            </Stack>

      );

    const renderEmailField = (title , index) => (

        <Stack spacing={1}>
            <Divider sx={{ border : "1px dashed" }}/>
            <Stack direction="row" sx={{pt:1}} alignItems="center">
                <Iconify icon="iconamoon:arrow-right-2-bold" />
                <Typography variant="body2">{title}</Typography>
            </Stack>
            <RHFTextField
                name={`email_${index}`}
                label="Ответ"
                fullWidth
                variant="filled"
            />
        </Stack>

    );
    const renderQAField = (title , index) => (

        <Stack spacing={1}>
            <Divider sx={{ border : "1px dashed" }}/>
            <Stack direction="row" sx={{pt:1}} alignItems="center">
                <Iconify icon="iconamoon:arrow-right-2-bold" />
                <Typography variant="body2">{title}</Typography>
            </Stack>
            <RHFTextField
                name={`qa_${index}`}
                label="Ответ"
                fullWidth
                variant="filled"
                valueToLowerCase
                multiline
            />
        </Stack>

    );
    const renderRadio = (title , options , index) => {

        const arOpts = options.map((item , ind)  => {
                const obj = {label: item , value: ind};
                return obj
            } ) ;

        return (
            <Stack spacing={1}>
                <Divider sx={{ border : "1px dashed" }}/>
                <Stack direction="row" sx={{pt:1}} alignItems="center">
                    <Iconify icon="iconamoon:arrow-right-2-bold" />
                    <Typography variant="body2">{title}</Typography>
                </Stack>
                <RHFRadioGroup
                    name={`radio_${index}`}
                    options={arOpts}
                    sx={{pl:1}}
                />
            </Stack>
            )
    }

    const renderCheckBox = (title , options , index) => {
        const arOpts = options.map((item , ind)  => {
            const obj = {label: item , value: ind};
            return obj
        } ) ;
        return (
            <Stack spacing={1} >
                <Divider sx={{ border : "1px dashed" }}/>
                <Stack direction="row" sx={{pt:1}} alignItems="center">
                    <Iconify icon="iconamoon:arrow-right-2-bold" />
                    <Typography variant="body2">{title}</Typography>
                </Stack>

                <RHFMultiCheckbox name={`checkbox_${index}`} options={arOpts} />
            </Stack>
        );

    }

    const renderDateField = (title , index) => (
          <Stack spacing={1}>
              <Divider sx={{ border : "1px dashed" }}/>
              <Stack direction="row" sx={{py:1}} alignItems="center">
                  <Iconify icon="iconamoon:arrow-right-2-bold" />
                  <Typography variant="body2">{title}</Typography>
              </Stack>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                  <DatePicker
                      value={values[`date_${index}`]}
                      label="Выбрать"
                      onChange={(newValue) => setValue(`date_${index}` , newValue)}
                     // renderInput={(params) => <RHFTextField {...params} name={`date_${index}`} variant="filled" />}
                      slotProps={{textField : {variant: "filled" , name: `date_${index}` }}}
                  />
              </LocalizationProvider>
          </Stack>
      )

    const renderTimeField = (titel , index) => (
          <TimePicker
              // value={timePickerValue}
              label="Время отправки"
              onChange={(newValue) => {
                  console.log("onChange" , newValue);
                  // setTimePickerValue(newValue)
              }}
              renderInput={(params) => <RHFTextField {...params} name="time" />}
          />
      )
    const setPhoneVal = (field , val) => {
          setPhone({...phone , [field]: val})
    }

    // const renderPhone = (title , index) => (
    //       <Stack spacing={1}>
    //           <Divider sx={{ border : "1px dashed" }}/>
    //           <Stack direction="row" sx={{py:1}} alignItems="center">
    //               <Iconify icon="iconamoon:arrow-right-2-bold" />
    //               <Typography variant="body2">{title}</Typography>
    //           </Stack>
    //           <RhfPhoneTextfield name={`phone_${index}`} label="Телефон" variant="filled" placeholder={t('form.start_typing')} />
    //           {/*<RHFPhoneInput name={`phone_${index}`} label="Телефон" variant="filled" placeholder={t('form.start_typing')} type='tel' />*/}
    //       </Stack>
    //   )
    const renderPhone = (title, index) => (
        <Stack spacing={1}>
            <Divider sx={{ border: "1px dashed" }} />
            <Stack direction="row" sx={{ py: 1 }} alignItems="center">
                <Iconify icon="iconamoon:arrow-right-2-bold" />
                <Typography variant="body2">{title}</Typography>
            </Stack>
            <RHFPhoneTextfield
                name={`phone_${index}`}
                label="Телефон"
                variant="filled"
                placeholder={t('form.start_typing')}
            />
        </Stack>
    );

 const additional_text = formFields?.additional_text|| t(`form.you_have_already_filled_out_the_form`)  ;
 const policy = formFields?.policy_link_text ? t(`form.policy`).replace(':link', formFields?.policy_link_text ): null ;
 if(complete){
   return (
      // <Alert variant="outlined" severity="success">
      //     {additional_text}
      // </Alert>
       <Markdown
           children={form?.additional_text?.replace(/\\n/g, '\n')||additional_text?.replace(/\\n/g, '\n')}
           sx={{
               p: 3,
               '& p, li, ol': {
                   typography: 'body2',
               },
               '& ol': {
                   p: 0,
                   display: { md: 'flex' },
                   listStyleType: 'none',
                   '& li': {
                       '&:first-of-type': {
                           minWidth: 240,
                           mb: { xs: 0.5, md: 0 },
                       },
                   },
               },
           }}
       />
   );
 }


const nameForm = formFields?.form_name || form?.form_name
  return(
    <ComponentBlock title={nameForm||t('form.form')}
                    borderType={borderType}
                    backgroundColor={backgroundColor}
                    backgroundOpacity={backgroundOpacity}
    >
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack spacing={1}>
                {formFields &&
                    <>

                    {formFields.questions.map((el , index) => (
                          <>
                              {el.type === 'text' &&
                                  <>
                                      {renderTextField(el.title , index)}
                                  </>
                              }
                               {el.type === 'phone' &&
                                  <>
                                      {renderPhone(el.title , index)}
                                  </>
                               }
                              {el.type === 'email' &&
                                  <>
                                      {renderEmailField(el.title , index)}
                                  </>
                              }
                              {el.type === 'one of list' &&
                                  <>
                                      {renderRadio(el.title , el?.options , index)}
                                  </>
                              }
                              {el.type === 'list of list' &&
                                  <>
                                       {renderCheckBox(el.title , el?.options , index)}

                                  </>
                              }
                              {el.type === 'verny_otvet' &&
                                  <>
                                      {renderQAField(el.title , index)}

                                  </>
                              }
                              {el.type === 'datetime' &&
                                  <>
                                      {renderDateField(el.title , index)}

                                  </>
                              }
                          </>
                        ))}

                    </>
                }
                {/* <Typography variant="subtitle2">Gender</Typography> */}

                {/* <RHFMultiSelect */}
                {/*    checkbox */}
                {/*    name="colors" */}
                {/*    label="Colors" */}
                {/*    options={PRODUCT_COLOR_NAME_OPTIONS} */}
                {/* /> */}

                {!policy &&
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        sx={{mt:1}}
                        loading={loading}
                    >
                        {t('system.send')}
                    </LoadingButton>
                }

                {policy &&
                    <>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        sx={{mt:1}}
                        loading={loading}
                        disabled={!checkedPolicy}
                    >
                        {t('system.send')}
                    </LoadingButton>
                    <FormGroup >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedPolicy}
                                onChange={handleChangePolicy}
                            />
                        }
                        label={
                            <Markdown
                                children={policy?.replace(/\\n/g, '\n')||""}
                                sx={{
                                    p: 1,
                                    '& p': {
                                        typography: 'caption',
                                    },
                                }}
                            />
                        }
                        sx={{mr:2}}
                    />
                    </FormGroup>
                    </>
                }

            </Stack>

        </FormProvider>
        </ComponentBlock>
  );
}

ArticleForm.propTypes = {
  form: PropTypes.object ,
  tgID: PropTypes.number ,
  tgUser: PropTypes.object ,
    onComplete: PropTypes.func ,
    borderType: PropTypes.string ,
    backgroundOpacity: PropTypes.number,
    backgroundColor : PropTypes.string ,

}
