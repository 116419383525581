import { useTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import Lottie from 'lottie-react';
import PropTypes from "prop-types"; // Укажите путь к вашему JSON-файлу
import animationData from '../../assets/skeleton-animation.json';

export default function StyledProgressBar({ size = 200 }) {
  const theme = useTheme();

  const inputGlobalStyles = (
    <GlobalStyles
      styles={{
        '#nprogress': {
          pointerEvents: 'none',
          '.bar': {
            top: 0,
            left: 0,
            height: 2.5,
            zIndex: 9999,
            width: '100%',
            position: 'fixed',
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0 0 2px ${theme.palette.primary.main}`,
          },
          '.peg': {
            right: 0,
            opacity: 1,
            width: 100,
            height: '100%',
            display: 'block',
            position: 'absolute',
            transform: 'rotate(3deg) translate(0px, -4px)',
            boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`,
          },
        },
      }}
    />
  );

  return (
    <>
      {inputGlobalStyles}
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Полупрозрачный белый фон
        zIndex: 9998, // Убедитесь, что анимация находится поверх других элементов
      }}>
        <Lottie
          animationData={animationData}
          loop
          autoplay
          style={{ width: size, height: size }} // Настройте размер анимации
        />
      </div>
    </>
  );
}

StyledProgressBar.propTypes = {
  size: PropTypes.number, // Размер анимации
};

StyledProgressBar.defaultProps = {
  size: 200, // Значение по умолчанию
};
