import * as React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {useEffect, useState} from "react";
import {Stack, Typography} from "@mui/material";

import Confetti from "react-confetti-boom";
import Iconify from "../../components/iconify";
import {useDispatch, useSelector} from "../../redux/store";


const drawerBleeding = 26;

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

export default function ArticlePointsSwipeDrawerDrawer({text , open , onClose}) {

    const [fid, setFid] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        // console.log("FID" , formId);
        // setFid(formId);

    };

    const dispatch = useDispatch();
    const [showForm , setShowForm] = useState(false);

    const {  curShopId  } = useSelector((state) => state.startform);


    return (
        <>
            <CssBaseline />
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(51% - ${drawerBleeding}px)`,
                        // height: `50%` ,
                        overflow: 'visible',

                    },
                }}
            />
             {/* <Box sx={{ textAlign: 'center', pt: 1 }}> */}
             {/*   <Button fullWidth color="primary" variant="contained" onClick={toggleDrawer(true)}>Кнопка</Button> */}
             {/* </Box> */}



            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={onClose}
                // onOpen={toggleDrawer( true)}
            >

              <StyledBox
                    sx={{
                        position: 'absolute',
                        top: -drawerBleeding,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        // visibility: 'visible',
                        right: 0,
                        left: 0,
                    }}
                >
                    <Puller />
                    <Box sx={{p:2}}/>
                    {/* <Typography sx={{ p: 2, color: 'text.secondary' }}>51 results</Typography> */}
                </StyledBox>
                <StyledBox
                    sx={{
                        px: 2,
                        pb: 2,
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    {open && <Confetti mode="fall"
                      // force={0.8}
                      // duration={3000}
                                       fadeOutHeight={0.8}
                                       particleCount={33}
                                       shapeSize={15}
                                       colors={['#1f76f2', '#ff884b' , '#e81b56' , '#f7e50f']} />}

                  <Box sx={{ textAlign: 'center', pt: 1 }}>

                        <Iconify icon="noto:wrapped-gift" width={48} />
                        <Stack spacing={2}>
                        <Typography variant="h5">Вы получили баллы</Typography>
                        <Typography variant="h2">{text}</Typography>
                        </Stack>
                    </Box>

                </StyledBox>
                <StyledBox sx={{px: 2, pb: 2}}>
                  <Button autoFocus variant="contained" fullWidth onClick={onClose} >
                      <Iconify icon="ic:round-close" />  Закрыть
                  </Button>
                </StyledBox>
            </SwipeableDrawer>

        </>
    );
}

ArticlePointsSwipeDrawerDrawer.propTypes = {
    text: PropTypes.bool ,
    open: PropTypes.bool ,
    onClose: PropTypes.func ,
};

