import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import ReactPhoneInput from "react-phone-input-material-ui";
import React from "react";
import ru from 'react-phone-input-material-ui/lang/ru.json';

// ----------------------------------------------------------------------

export default function RHFPhoneInput({ name, helperText, type, placeholder, ...other }) {
    const { control } = useFormContext();

    // Очистка номера телефона
    const cleanPhoneValue = (value) =>
         value.replace(/[^\d+]/g, '') // Удаляем всё, кроме цифр и +
    ;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <ReactPhoneInput
                    {...field}
                    value={field.value}
                    placeholder={placeholder}
                    onChange={(value) => {
                        const cleanedValue = cleanPhoneValue(value); // Очищаем значение
                        field.onChange(cleanedValue); // Передаём очищенное значение
                    }}
                    inputProps={{
                        type,
                        variant: "filled",
                        required: true,
                        autoFocus: true,
                        error: !!error,
                        helperText: error ? error.message : helperText,
                        ...other,
                    }}
                    component={TextField}
                    localization={ru}
                />
            )}
        />
    );
}

RHFPhoneInput.propTypes = {
    helperText: PropTypes.object,
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
};
