
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    orderLoading: false ,
    changeStatusLoading: false ,
    error: null,
    // article: [],
    orders: [],
    order: [] ,
    changedStatusSuccess: null ,
    // forms: [],
};

const slice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        startOrderLoading(state) {
            state.orderLoading = true;
        },

        setChangeStatusLoading(state , action) {
            state.changeStatusLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },


        setOrdersSuccess(state, action) {
            state.isLoading = false;
            state.orders = action.payload;
        },

        setOrderSuccess(state, action) {
            state.orderLoading = false;
            state.order = action.payload;
        },

        setStatusSuccess(state, action) {
            state.isLoading = false;
            state.changedStatusSuccess = action.payload;
        },


    },
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function setLoading(val){
    return (dispatch) => {
        dispatch(slice.actions.setLoading(val));
    }
}


export function getOrders(shopid) {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            // const response = await axios.get(`/statistic/products/${shopid}`);
            const response = await axios.get(`/orders/${shopid}`);
// console.log("response getOrders" , response);
            if(!response?.data?.success){
                alert(response?.data?.data)
                throw new Error(`${response?.data?.data}`);
            }else{
                dispatch(slice.actions.setOrdersSuccess(response.data.data));
                dispatch(slice.actions.setStatusSuccess(null))
            }

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOrderById(shopid , id) {

    return async (dispatch) => {
        dispatch(slice.actions.startOrderLoading());
        try {
            // const response = await axios.get(`/statistic/products/${shopid}`);
            const response = await axios.get(`/orders/${shopid}/${id}`);

            if(!response?.data?.success){
                alert(response?.data?.data)
                throw new Error(`${response?.data?.data}`);
            }else{
                dispatch(slice.actions.setOrderSuccess(response.data.data));
            }

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setOrderStatus(shopid , id , status) {

    return async (dispatch) => {
        dispatch(slice.actions.setChangeStatusLoading(true));
        try {
            // const response = await axios.get(`/statistic/products/${shopid}`);
            const response = await axios.patch(`/orders/${shopid}/${id}` , {status});
            // console.log("response" , response);

            if(!response?.data?.success){
                alert(response?.data?.data)
                throw new Error(`${response?.data?.data}`);
            }else{
                /// setStatusSuccess
                dispatch(slice.actions.setStatusSuccess(id));
            }
            dispatch(slice.actions.setChangeStatusLoading(false));

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.setChangeStatusLoading(false));
            dispatch(slice.actions.hasError(error));
        }
    };
}
