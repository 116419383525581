import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";

import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import {ScopedCssBaseline} from "@mui/material";
import {isArray} from "lodash";
import {useCallback} from "react";
import Iconify from "../../../components/iconify";
import TextMaxLine from "../../../components/text-max-line";
import {isTgLink, routerClick, selfLink} from "../../../utils/article-self-link";
import {replaceIconCode} from "../../../utils/article-codes";
import {useRouter} from "../../../routes/hooks";
import {useSelector} from "../../../redux/store";


const tg = window.Telegram?.WebApp;



export default function ArticleMarkdownOL({node , nodeWithLi}){

    const { curShopId } = useSelector((state) => state.startform)
    const olNode = nodeWithLi || node?.node.children;
    const origNode = node?.children;

    const elements = olNode.filter(el => el.type === 'element');
    const li = elements.filter(el => el.tagName === 'li');

    const liChildren  = nodeWithLi || li.map(el => el.children);

    // console.log("=>> node" , node);
    // const liChildren = li.filter()
   // console.log("origNODE" , origNode);
    // eslint-disable-next-line array-callback-return

    const orgNode = isArray(origNode);

    return (
        <>
            {liChildren?.length > 0 &&
                <List>
                    {liChildren?.map((item, index) => (
                        <Item key={index}  index={index} item={item||[]} origNode={orgNode ? origNode[index] :[]} last={liChildren?.length === index+1} shopid={curShopId}/>
                    ))}
                </List>
            }
        </>

    );
}

ArticleMarkdownOL.propTypes = {
    node: PropTypes.any,
    nodeWithLi: PropTypes.any,
}

function Item({item , origNode ,index , last , shopid}){
    // console.log("item" , item);
    const router = useRouter();
    const isHead = item.findIndex(el => el.tagName === 'strong')
    const isSubhead = item.findIndex(el => el.type === 'text')
    const isAction = item.find(el => el.tagName === 'a')

    // console.log("isHead" , isHead);
    const objHead = isHead === 0 ? replaceIconCode(item[0].children[0].value , index) : null ;
    // console.log("objHead" , objHead);
    const head = isHead === 0 ?  item[0].children[0].value :null;
    const subHead = isSubhead === 1 ? item[1].value :null;
    const noList = (head === null && subHead === null);
    let _subH = "";
    let subhWrap = [];

    if(!noList){
        const itemWithoutHead = item.filter((el , ind) => ind > 0 );

            itemWithoutHead.forEach(el => {

            if(el.type === 'text'){
                _subH = `${_subH}${el.value}`
            }else if(el.type === 'element'){
                if(el.tagName === 'strong'){
                    _subH = `${_subH} ${el.children[0].value}`
                }else if(el.tagName === 'em') {
                    _subH = `${_subH} ${el.children[0].value}`
                }
                // }else if(el.tagName === 'a'){
                //     _subH = `${_subH} <strong>${el.children[0].value}</strong>`
                // }
            }
        })
        subhWrap = _subH.split('\n');

    }


    // const action = isAction ? {text: item.children[2].value , href: item.properties.href }:null;
    // const handleClick = useCallback((link) => {
    //     const lnk = selfLink(link , shopid);
    //     console.log("self Link" , lnk);
    //     if(lnk?.type==='router'){
    //         router.push(lnk?.link);
    //     }else if(lnk?.type === 'location'){
    //         if(isTgLink(lnk?.link))tg.openTelegramLink(lnk?.link);
    //         else window.location.href = lnk?.link;
    //     }
    // } , [router, shopid])
    const handleHref = (elementA) => {
        const lnk = elementA.properties.href;
        // console.log("LNK" , lnk);
        routerClick(lnk , shopid , router , tg)
        // if(isTgLink(lnk))tg.openTelegramLink(lnk);
        // else window.location.href = elementA.properties.href;
    }
    return(
        <>
            {!noList &&
                <>
                    <ListItem alignItems="flex-start"
                        secondaryAction={
                        <>
                            {isAction &&
                                <IconButton edge="end"
                                            // onClick={() => handleHref(isAction)}
                                            sx={{ mr:-3}}>
                                    <Iconify icon="material-symbols:keyboard-arrow-right"/>
                                </IconButton>
                            }
                        </>
                        }
                        onClick={() => isAction ? handleHref(isAction) : null}
                        sx={{p:1 }}
                    >
                        <ListItemIcon sx={{mr:1 , mt:0.5}}>
                            {objHead?.icon.map(ico => <Iconify icon={ico} />)}

                                  {/* <Iconify icon="noto:check-mark-button" /> */}
                            {/* <Iconify icon="noto-v1:check-box-with-check" /> */}
                            {/* <Iconify icon="streamline-emojis:ballot-box-with-check" /> */}
                             {/* <Iconify icon={`fluent-emoji-flat:keycap-${index+1}`} /> */}
                        </ListItemIcon>
                        <ListItemText
                            primary={objHead?.text}
                            secondary={subhWrap.map(str => <Typography variant="caption">{str}</Typography>)

                            // <TextMaxLine
                            //     persistent
                            //     variant="caption"
                            //     sx={{ mr: 1}}
                            // >
                            //     {_subH}
                            // </TextMaxLine>
                            //     <Typography variant="caption" color="text.disabled">{_subH}</Typography>
                        }
                            // secondaryTypographyProps={{variant: "caption"}}

                            sx={{mr:1}}
                        />

                    </ListItem>
                    {!last &&
                        <Divider component="li" sx={{borderStyle: 'dashed'}}/>
                    }

                </>
            }
            {noList &&
            <ListItem alignItems="flex-start" >
                    <ListItemIcon sx={{mr:1 , mt:0.5}}>
                      <Iconify icon="noto-v1:check-box-with-check" />
                    </ListItemIcon>
                    <Typography sx={{lineHeight: 1}}> <ScopedCssBaseline>{origNode}</ScopedCssBaseline></Typography>
                </ListItem>
            }
        </>

    )
}

Item.propTypes = {
    item: PropTypes.array ,
    origNode: PropTypes.object ,
    last: PropTypes.bool ,
    index: PropTypes.number ,
    shopid: PropTypes.string ,
}
