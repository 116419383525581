import useSWR , { useSWRConfig } from 'swr';
import { useMemo } from 'react';
// utils
import { fetcher, endpoints } from 'src/utils/axios';

import {_mockProducts} from 'src/_mock'


// ----------------------------------------------------------------------
const _products = _mockProducts ;
export function useGetProducts(shopid , userData = null , showunpublished = false , initData = null ) {

  // const URL = `${endpoints.product.list}/${shopid}?tg_id=${userData}&hash=${initData}`;
  const URL = `${endpoints.product.list}/${shopid}`;

  const { data,
          isLoading,
          error,
          isValidating, mutate } = useSWR([URL , {tg_id: userData}], fetcher);

  // убрать когда будет свой API

    const allProd = data || [] ;
    const productsPublished =  allProd .filter(el => el.published)  ;
    const _prod = showunpublished ? allProd  : productsPublished;

  const memoizedValue = useMemo(
    () => ({
      products: data ? data.filter(el => el.published) : []  ,
      all: data || [],
      productsLoading: isLoading,
      productsError: error,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !data?.length,
      update: mutate ,
    }),
    [data,  isLoading, error, isValidating , mutate]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetAdminProducts(shopid  ) {
    const URL = `${endpoints.product.admin_list}/${shopid}`;

    const { data,
        isLoading, error, isValidating } = useSWR([URL ], fetcher);
    // убрать когда будет свой API
    // console.log("data => " , data);

    const memoizedValue = useMemo(
        () => ({
            products: data ? data.filter(el => el.published) : [] ,
            all: data || [],
            productsLoading: isLoading,
            productsError: error,
            productsValidating: isValidating,
            productsEmpty: !isLoading && !data?.length,
        }),
        [data, error, isLoading, isValidating]
    );

    return memoizedValue;
}

export function useGetProduct(shopid , productId , parsing=1 , tg_id=null , initData=null ,  ) {
  // const URL = productId ? [endpoints.product.details, { params: { productId } }] : null;
    // const URL = productId ? `${endpoints.product.details}/${shopid}/${productId}?tg_id=${tg_id}&parsing=${parsing}&hash=${initData}` : null;
    const URL = productId ? `${endpoints.product.details}/${shopid}/${productId}?parsing=${parsing}` : null;

    const swr = useSWR([URL], fetcher);
    const {data , isLoading, error, isValidating} = swr ;
      // const {
      //     data,
      //     isLoading, error, isValidating
      // } = useSWR(URL, fetcher);
      // const _data  = _products;
      // const data = {product: _data.products.find(item => item.id === productId)}

      const memoizedValue = useMemo(
          () => ({
              product: data,
              productLoading: isLoading,
              productError: error,
              productValidating: isValidating,
          }),
          [data, error, isLoading, isValidating]
      );

      return memoizedValue;
}

export function useGetPublicLock(shopid , productId ){
    // /get_public/{shop_id}/{product_id}
    const URL = productId ? `${endpoints.product.group_lock_list}/${shopid}/${productId}` : null;
    // console.log("useSWR(URL, fetcher)" , endpoints.product.group_lock_list);
    const { data
        , error, isValidating } = useSWR([URL], fetcher);

    const memoizedValue = useMemo(
        () => ({
            publics: data,
            // publicsLoading: isLoading,
            publicsError: error,
            publicsValidating: isValidating,
        }),
        [data, error,  isValidating]
    );

    return memoizedValue;
}


export function useGetAdminProduct(shopid , productId , parsing=1 , tg_id=null) {
    // const URL = productId ? [endpoints.product.details, { params: { productId } }] : null;
    const URL = productId ? `${endpoints.product.admin_list}/${shopid}/${productId}` : null;

    const { data ,
        isLoading, error, isValidating } = useSWR(URL, fetcher);
    // const _data  = _products;
    // const data = {product: _data.products.find(item => item.id === productId)}
       //  console.log("data =>" , data);
    const memoizedValue = useMemo(
        () => ({
            product: data,
            productLoading: isLoading,
            productError: error,
            productValidating: isValidating,
        }),
        [data, error, isLoading, isValidating ]
    );

    return memoizedValue;
}

// ----------------------------------------------------------------------

export function useSearchProducts(query) {
  const URL = query ? [endpoints.product.search, { params: { query } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.results || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
      searchEmpty: !isLoading && !data?.results.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function getCategories (items) {
    const arCategs = items?.map(prod => prod.category)||[];
    const newSet = new Set(arCategs);
    const uniqCat = Array.from(newSet);
    return uniqCat;
}


