import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
// import { parsePhoneNumberFromString } from 'libphonenumber-js';

// ----------------------------------------------------------------------

export default function RHFPhoneTextfield({ name, helperText, type, placeholder, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    type="tel"
                    variant="filled"
                    label="Телефон"
                    placeholder={placeholder}
                    error={!!error}
                    helperText={error ? error.message : helperText}
                    onChange={(e) => {
                        // Очищаем значение: оставляем только цифры и +
                        const cleanedValue = e.target.value.replace(/[^\d+]/g, '');

                        // Если номер начинается с 8, заменяем на +7
                        let normalizedValue = cleanedValue;
                        if (normalizedValue.startsWith('8')) {
                            normalizedValue = `+7${normalizedValue.slice(1)}`;
                        }
                        // Если номер начинается с 7, добавляем +
                        else if (normalizedValue.startsWith('7') && !normalizedValue.startsWith('+7')) {
                            normalizedValue = `+${normalizedValue}`;
                        }
                        // Если номер не начинается с +, добавляем +
                        else if (!normalizedValue.startsWith('+') && normalizedValue.length > 0) {
                            normalizedValue = `+${normalizedValue}`;
                        }

                        // Обновляем значение поля
                        field.onChange(normalizedValue);
                    }}
                    value={field.value} // Отображаем очищенное значение
                    {...other}
                />
            )}
        />
    );
}

RHFPhoneTextfield.propTypes = {
    helperText: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
};
