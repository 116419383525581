import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import {useState} from "react";
import {Box} from "@mui/material";
import {useSelector} from "../../../redux/store";
import {paths} from "../../../routes/paths";
import {useRouter} from "../../../routes/hooks";
import {replaceButtonCode} from "../../../utils/article-codes";
import Iconify from "../../../components/iconify";
import ArticleFormDialog from "../forms/article-form-dialog";
import {isTgLink} from "../../../utils/article-self-link";

// ---------------TELEGRAMM ---------------
const tg = window.Telegram?.WebApp;
// --------------- END --------------------

export function ArticleMarkdownButton({ text ,  link  , tgUser , sx , banner , stories ,size , color , fullWidth=false }){


    const router = useRouter();
    const {  curShopId   } = useSelector((state) => state.startform);
    const [openFDialog , setOpenFDialog] = useState(false);

    let showBtn = false;
    const origText = Array.isArray(text) ? text[0] : text ;

    let cfgBtn = {text: origText , variant: "contained" , color: "warning" }


    const elemText = Array.isArray(text)  ? text.find(item => typeof item==="string" && item.includes('{кнопка}') ) : null ;

    if(typeof elemText === 'string'){
        // console.log("A" , props);
        showBtn = true;
        const reqxKnopka = /{кнопка}/gi;

       // const btnText = showBtn ? origText.replace(reqxKnopka , ''): origText;
        cfgBtn = {...cfgBtn , text: origText.replace(reqxKnopka , '')}
    }
    if(!showBtn){
        const codeBtn = replaceButtonCode(origText);
        if(codeBtn){
            cfgBtn = codeBtn;
            showBtn = true;
        }
    }

    const reqxLink =  /post#([\w\d]+)/g;
    const reqxLinkGood =  /good#([\w\d]+)/g;
    const reqxLinkForm =  /form#([\w\d]+)/g;
    const arLinkGood =  link ? [...link.matchAll(reqxLinkGood)] : [];
    const arLink = link ? [...link.matchAll(reqxLink)] : [];
    const arLinkForm = link ?  [...link.matchAll(reqxLinkForm)] : [];

    function getElementID(arrLink){
        if(arrLink.length > 0 ){
            if(arrLink[0].length > 0 && arrLink[0][1] !== undefined){
                return arrLink[0][1];
            }
        }
        return null;
    }


    const postID = getElementID(arLink);
    const goodID = getElementID(arLinkGood);
    const formID = getElementID(arLinkForm);

    // if(arLink.length > 0 && arLink[0][1] !== undefined){
    //     postID = arLink[0][1];
    // }
    //
    // if(arLinkGood.length > 0 && arLinkGood[0][1] !== undefined){
    //
    //     goodID = arLinkGood[0][1];
    // }


    const handleOnClick = () => {
        if(postID){
            // console.log("LINK" , link);
            // console.log("curShopId , postID" , curShopId , postID);
            router.push(paths.product.article(curShopId , postID));
        }else if(goodID){
            router.push(paths.product.details(curShopId , goodID));
        }else if(formID){
            // console.log("FORM");
            setOpenFDialog(true);
        }
        else if(isTgLink(link)){
                tg.openTelegramLink(link);
            }else{
            // eslint-disable-next-line no-lonely-if
              if(tgUser?.id)tg.openLink(link);
                else window.location.href = link;

               // window.location.href = link;
            }
    }

    const handleCloseFDialog = () => {
        setOpenFDialog(false);
    }


    const renderBtn = (
        <Button color={color || cfgBtn.color}
                                variant={cfgBtn.variant}
                                size={size || 'medium'}
                fullWidth={fullWidth}

                                onClick={handleOnClick}
                                startIcon={cfgBtn?.icon?.position === 'start' ? <Iconify icon={cfgBtn?.icon?.code}/> : null }
                                endIcon={cfgBtn?.icon?.position === 'end' ? <Iconify icon={cfgBtn?.icon?.code}/> : null }
                                sx={{...(banner && { mr: 0.5 , mt:0.5 }) ,
                                    ...(stories && {...sx})
                                }}
    >
        {cfgBtn.text}
    </Button>);

    return(
        <Box sx={sx}>
            {(showBtn || stories) &&
                <Stack spacing={2} sx={{my:2}}>
                    {renderBtn}
                </Stack>
            }
            {banner && renderBtn}
            {(!showBtn && !banner && !stories) &&
                <Link onClick={handleOnClick}>
                    {cfgBtn.text}
                </Link>
            }
            {formID &&
                <ArticleFormDialog
                    formID={formID}
                    open={openFDialog}
                    handleClose={handleCloseFDialog}
                    tgUser={tgUser}
                />
            }
        </Box>
    );
}

ArticleMarkdownButton.propTypes = {
    text: PropTypes.any ,
    link: PropTypes.string ,
    tgUser: PropTypes.object ,
    sx: PropTypes.object ,
    banner: PropTypes.bool ,
    stories: PropTypes.bool ,
    size: PropTypes.string ,
    color: PropTypes.string ,
    fullWidth: PropTypes.bool ,

}
