import {paths} from "../routes/paths";
import {useRouter} from "../routes/hooks";
import {isTgLink} from "./article-self-link";
// import {replaceButtonCode} from "./article-codes";
import {ARRAY_ACCESS_TARIFF, HSLIDER_MAXLINE_WIDTH} from "../config-global";
// import {generateUUID} from "./gen-uuid4";

const tg = window.Telegram.WebApp;
export default function parserNotibotLink(link , curShopId)  {

    const reqxLink =  /post#([\w\d]+)/g;
    const reqxLinkGood =  /good#([\w\d]+)/g;
    const arLink =  [...link.matchAll(reqxLink)];
    const arLinkGood =  [...link.matchAll(reqxLinkGood)];


    let postID = null;
    let goodID = null;

    if(arLink.length > 0 && arLink[0][1] !== undefined){
        // console.log("regx" , arLink[0][1]);
        postID = arLink[0][1];
    }

    if(arLinkGood.length > 0 && arLinkGood[0][1] !== undefined){
        // console.log("regx good" , arLinkGood[0][1]);
        goodID = arLinkGood[0][1];
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const router = useRouter();
    if(postID){
        // console.log("LINK" , link);
        router.push(paths.product.article(curShopId , postID));
    }else if(goodID){
        router.push(paths.product.details(curShopId , goodID));
    }
    else if(isTgLink(link))tg.openTelegramLink(link);
        else window.location.href = link;
}

export function getAccessTariff (userTarif){
    // если доступ есть то венуть true

    return  ARRAY_ACCESS_TARIFF.indexOf(userTarif) > -1 ;
}

export function getWidthHSliderByCountItem(countLine , w='min'){
    const width = HSLIDER_MAXLINE_WIDTH.find(el => el.len === countLine);
    return w === 'min' ? width?.mw : width?.maxw;
}

export function updateUsercardBlocks(blocks , updatedElement , changeBlockId){
    const curIndex = blocks.findIndex(item => item.id === changeBlockId)
    // console.log("curIndex" , curIndex);
    const new_blocks = [...blocks]
    // console.log("new_blocks" , new_blocks);
    // console.log("updatedElement" , updatedElement);
    new_blocks.splice(curIndex , 1 , updatedElement )
    return new_blocks;
}

// --- принимает массив с текущими значениями  currentImages и Новое значени (либо строка либо массив)
// -- возвращает сроку созданную из массива с разделителем ','
export function imagesStringAfterUploadNewImage(currentImages , newImages , max = 10 ){

    let newVal = '';
    if(typeof newImages === 'string')newVal = newImages;
    let newImgs = []
    if (Array.isArray(newImages)) {
        // const cnt = newImages?.length || 0
        newImgs = max === 1 ? [...newImages] : [...currentImages , ...newImages]
        //
        // if (cnt === 1) newVal = newImages[0]
        // if (cnt > 1) newVal = newImages.join(',')
    }else{
        // пришла одна картинка и она строка
        newImgs = max > 1 ? [...currentImages ] : []
        newImgs.push(newImages)
    }

    // удаляем дубли
    const newSet = new Set(newImgs)
    const noDublImages = Array.from(newSet)

    const newArr = noDublImages.length >= max ? noDublImages.slice(0,max) : noDublImages;
    newVal = newArr.join(',')

    return newVal
}

// export function transformFormatUsercard (blocks) {
//     console.log("currentCard?.blocks" , blocks);
//     let newArr = [];
//     const actual = blocks?.actual;
//     const infList = blocks?.info_list;
//     const banner = blocks?.banner;
//     const hslider = blocks?.hslider;
//     const head = blocks?.head;
//     const gallery = blocks?.gallery;
//     const reviews = blocks?.reviews;
//     const actualItem = {
//         type: 'actual' ,
//         id: generateUUID() ,
//         show:actual?.show ,
//         sort: actual?.sort,
//         content:{title: actual?.title , category: actual?.category , content_type: actual?.content_type}
//     };
//     const infoListItem = {
//         type: 'info_list' ,
//         id: generateUUID() ,
//         show:infList?.show ,
//         sort: infList?.sort,
//         content:{title: infList?.title , category: infList?.category , content_type: infList?.content_type}
//     };
//     const reviewsItem = {
//         type: 'reviews' ,
//         id: generateUUID() ,
//         show:reviews?.show ,
//         sort: reviews?.sort,
//         content:{}
//     };
//     const galleryItem = {
//         type: 'gallery' ,
//         id: generateUUID() ,
//         show:gallery?.show ,
//         sort: gallery?.sort,
//         content:{}
//     };
//     const headItem = {
//         type: 'head' ,
//         id: generateUUID() ,
//         show:head?.show ,
//         content:{}
//     };
//     const hsliderItem = {
//         type: 'hslider' ,
//         id: generateUUID() ,
//         show:hslider?.show ,
//         content:{items: hslider?.items, title: hslider?.title}
//     };
//     const bannerItem = {
//         type: 'banner' ,
//         id: generateUUID() ,
//         show:banner?.show ,
//         content:{title: banner?.title ,
//             subheader: banner?.subheader ,
//             link: banner?.link ,
//             image: banner?.image,
//             btn_text: banner?.btn_text
//         }
//     };
//
//     newArr = [...newArr , actualItem , bannerItem , headItem , infoListItem , hsliderItem , galleryItem , reviewsItem ]
//     return newArr;
// }
