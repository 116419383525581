import { height, width } from '@mui/system'

export const storyData = [
    {
        id: 'story11',
        type: 'main_app',
        slides: [
            {
                url: '/assets/stories/onboarding/main/1.png',

                type: "img",

                alt: 'slide 1',
                background: '#fafafa',
                overlays: [
                    // {
                    //     type: 'text',
                    //     id: '231',
                    //     value: 'AAAAAAA',
                    //     styles: {
                    //         color: 'orange',
                    //         'font-size': '16px',
                    //         top: '60%',
                    //         left: '30%',
                    //     }
                    // }
                ]

            },
            {
                url: '/assets/stories/new_business_card/Frame_2.png',
                type: "img",
                alt: 'slide 2',
                background: '#fafafa',
            },
            {
                url: '/assets/stories/goods/1.png',
                type: 'image',

                alt: 'slide 3',
                background: '#1abc63',

            },
            {
                url: '/assets/stories/pages/1.png',
                type: 'image',

                alt: 'slide 4',
                background: '#1abc63',

            },
            // {
            //     url: '/assets/stories/pages/8.png',
            //     type: 'image',
            //
            //     alt: 'slide 5',
            //     // background: 'linear-gradient(180deg, #fafafa 40%, #19bc62 60%)',
            //     background: '#1abc63',
            //
            // },
            {
                url: '/assets/stories/chatbot/1.png',
                type: 'image',

                alt: 'slide 6',
                background: '#1abc63',

            } ,
            {
                url: '/assets/stories/chatbot/6.png',
                type: 'image',

                alt: 'slide 7',
                background: '#1abc63',

            }
        ]
    },
    {
        id: 'story1',
        type: 'new_business_card',
        slides: [
            {
                url: '/assets/stories/new_business_card/Frame_2.png',

                type: "img",

                alt: 'slide 1',
                background: '#fafafa',
                overlays: [
                    // {
                    //     type: 'text',
                    //     id: '231',
                    //     value: 'AAAAAAA',
                    //     styles: {
                    //         color: 'orange',
                    //         'font-size': '16px',
                    //         top: '60%',
                    //         left: '30%',
                    //     }
                    // }
                ]

            },
            {
                url: '/assets/stories/new_business_card/Frame_3.png',

                type: 'img',
                alt: 'slide 2',
                background: '#1abc63',


            },
            {
                url: '/assets/stories/new_business_card/Frame_4.png',
                type: 'image',

                alt: 'slide 3',
                background: '#1abc63',

            },
            {
                url: '/assets/stories/new_business_card/Frame_5.png',
                type: 'image',

                alt: 'slide 4',
                background: '#1abc63',

            },
            {
                url: '/assets/stories/new_business_card/Frame_6.png',
                type: 'image',

                alt: 'slide 5',
                background:  '#fafafa' , // 'linear-gradient(180deg, #fafafa 40%, #19bc62 60%)',

            },
            {
                url: '/assets/stories/new_business_card/Frame_7.png',
                type: 'image',

                alt: 'slide 6',
                background: '#2b2b2b',

            }
        ]
    },
    {
        id: 'story4',
        type: 'onboarding_main',
        slides: [
            {
                url: '/assets/stories/onboarding/main/1.png',

                type: "img",

                alt: 'slide 1',
                background: '#fafafa',
                overlays: [
                    // {
                    //     type: 'text',
                    //     id: '231',
                    //     value: 'AAAAAAA',
                    //     styles: {
                    //         color: 'orange',
                    //         'font-size': '16px',
                    //         top: '60%',
                    //         left: '30%',
                    //     }
                    // }
                ]

            },
            {
                url: '/assets/stories/onboarding/main/2.png',

                type: 'img',
                alt: 'slide 2',
                background: '#2b2b2b',


            },
            {
                url: '/assets/stories/onboarding/main/3.png',
                type: 'image',

                alt: 'slide 3',
                background: '#1abc63',

            },
            {
                url: '/assets/stories/onboarding/main/4.png',
                type: 'image',

                alt: 'slide 4',
                background: '#1abc63',

            },
            {
                url: '/assets/stories/onboarding/main/5.png',
                type: 'image',

                alt: 'slide 5',
               // background: 'linear-gradient(180deg, #fafafa 40%, #19bc62 60%)',
                background: '#1abc63',

            },
            {
                url: '/assets/stories/onboarding/main/6.png',
                type: 'image',

                alt: 'slide 6',
                background: '#1abc63',

            }
        ]
    },
    {
        id: 'story5',
        type: 'onboarding_main_en',
        slides: [
            {
                url: '/assets/stories/onboarding/main/en/1.png',

                type: "img",

                alt: 'slide 1',
                background: '#fafafa',
                overlays: [
                    // {
                    //     type: 'text',
                    //     id: '231',
                    //     value: 'AAAAAAA',
                    //     styles: {
                    //         color: 'orange',
                    //         'font-size': '16px',
                    //         top: '60%',
                    //         left: '30%',
                    //     }
                    // }
                ]

            },
            {
                url: '/assets/stories/onboarding/main/en/2.png',

                type: 'img',
                alt: 'slide 2',
                background: '#2b2b2b',


            },
            {
                url: '/assets/stories/onboarding/main/en/3.png',
                type: 'image',

                alt: 'slide 3',
                background: '#1abc63',

            },
            {
                url: '/assets/stories/onboarding/main/en/4.png',
                type: 'image',

                alt: 'slide 4',
                background: '#1abc63',

            },
            {
                url: '/assets/stories/onboarding/main/en/5.png',
                type: 'image',

                alt: 'slide 5',
                // background: 'linear-gradient(180deg, #fafafa 40%, #19bc62 60%)',
                background: '#1abc63',

            },
            {
                url: '/assets/stories/onboarding/main/en/6.png',
                type: 'image',

                alt: 'slide 6',
                background: '#1abc63',

            }
        ]
    },
    {
        id: 'story6',
        type: 'onboarding_game_rules',
        slides: [
            {
                url: '/assets/stories/game/onboard_game_rules_like.png',
                background: '#1abc63',
                overlays: [
                    {
                        type: 'text',
                        id: '231',
                        value: '### Баллы за реакции\n' +
                            '→ Ставьте реакцию на посте в виде сердечка, огонечка или любую другую реакцию\n' +
                            '\n' +
                            'Баллы за реакции **распределяются в равных количествах** между всеми участниками, кто оставил реакцию на посте.\n' +
                            '\n' +
                            '***Первые баллы не могут быть начислены за реакции***\n' ,
                            styles: {
                            color: 'white',

                            fontSize: '18px',
                            fontWeight: "bold" ,
                            top: '10%',
                            left: '5%',
                            right: '5%' ,
                        }
                    } ,
                    // {
                    //                     type: "text",
                    //                     content: "ывавыавыаывавыавыавы",
                    //                     color: "#000000",
                    //                     backgroundColor: "#8bc34a",
                    //                     fontWeight: "normal",
                    //                     fontSize: 14,
                    //                     position: {
                    //                         x: -74,
                    //                         y: 168
                    //                     },
                    //                     size: {
                    //                         width: 182,
                    //                         height: 14
                    //                     }
                    //                 }
                ]

            },
            {
                url: '/assets/stories/game/onboard_game_rules_2.png',
                background: '#1abc63',
                overlays: [
                    {
                        type: 'text',
                        id: '232',
                        value: '### Баллы за комментарии\n' +
                            'Пишите комментарии к постам, которые вы еще не комментировали, так как баллы начисляются ***только за уникальные активности в канале***\n' +
                            '\n'+
                        '***Баллы начисляются только за комментарии, состоящие не менее чем из 15 символов***'
                        ,
                        styles: {
                            color: 'white',

                            fontSize: '18px',
                            fontWeight: "bold" ,
                            top: '10%',
                            left: '5%',
                            right: '5%' ,
                        }
                    } ,
                    // {
                    //                     type: "text",
                    //                     content: "ывавыавыаывавыавыавы",
                    //                     color: "#000000",
                    //                     backgroundColor: "#8bc34a",
                    //                     fontWeight: "normal",
                    //                     fontSize: 14,
                    //                     position: {
                    //                         x: -74,
                    //                         y: 168
                    //                     },
                    //                     size: {
                    //                         width: 182,
                    //                         height: 14
                    //                     }
                    //                 }
                ]

            },
            {
                // url: '/assets/stories/game/onboard_game_rules_2.png',
                background: '#1abc63',
                overlays: [
                    {
                        type: 'text',
                        id: '233',
                        value: '### 💎 Баллы за приглашения друзей\n' +
                            'Скопируй свою ссылку и пригласи друга.\n' +
                            'Когда друг перейдет по ссылке и вступит в канал, тебе начислятся баллы ✨\n' +
                            '\n' +
                            'Размести свою ссылку:\n'+
'— в stories telegram и instagram\n'+
'— в тематических чатах telegram\n'+
'— у себя в канале\n'+
'\n' +
                            '‼️ ***За приглашение фейков и ботов — бан!***\n'+
                            '![пригласи друга](/assets/stories/game/onboard_game_rules_3.gif)'
                        ,
                        styles: {
                            color: 'white',

                            fontSize: '15px',
                            fontWeight: "bold" ,
                            top: '5%',
                            left: '5%',
                            right: '5%' ,
                        }
                    } ,
                    // {
                    //                     type: "text",
                    //                     content: "ывавыавыаывавыавыавы",
                    //                     color: "#000000",
                    //                     backgroundColor: "#8bc34a",
                    //                     fontWeight: "normal",
                    //                     fontSize: 14,
                    //                     position: {
                    //                         x: -74,
                    //                         y: 168
                    //                     },
                    //                     size: {
                    //                         width: 182,
                    //                         height: 14
                    //                     }
                    //                 }
                ]

            },

        ]
    },
    // {
    //     id: 'story2',
    //     slides: [
    //         {
    //             url: 'https://via.placeholder.com/1080x1920/00c322/00c322?text=Story+21',
    //             alt: 'slide 1',
    //             overlays: [
    //                 {
    //                     type: 'text',
    //                     id: '231',
    //                     value: 'AAAAAAA',
    //                     styles: {
    //                         color: 'orange',
    //                         'font-size': '16px',
    //                         top: '60%',
    //                         left: '30%',
    //                     }
    //                 }
    //             ]
    //         },
    //         {
    //             url: 'https://via.placeholder.com/1080x1920/00FF00/FFFFFF?text=Story+22',
    //             alt: 'slide 2',
    //         },
    //         {
    //             url: 'https://via.placeholder.com/1080x1920/00FF00/FFFFFF?text=Story+23',
    //             alt: 'slide 3',
    //         }
    //     ]
    // },
    {
        id: 'story3',
        slides: [
            {
                url: 'https://cs13.pikabu.ru/post_img/big/2023/12/20/12/1703105085117037.jpg',
                alt: 'slide 1',
                // overlays: [
                //     {
                //         type: 'text',
                //         id: '123',
                //         value: 'AAAAAAAAAAAAAAA',
                //         styles: {
                //             color: 'orange',
                //             'font-size': '16px',
                //             top: '60%',
                //             left: '30%',
                //         }
                //     },
                //     {
                //         type: 'text',
                //         id: '111',
                //         value: 'dsdsdsdsds',
                //         styles: {
                //             color: 'green',
                //             'font-size': '16px',
                //             top: '20%',
                //             left: '20%',
                //         }
                //     }
                // ]
            },
            {
                url: 'https://via.placeholder.com/1080x1920/00FF00/FFFFFF?text=Story+12',
                alt: 'slide 2',
                // overlays: [
                //     {
                //         type: 'text',
                //         id: '321',
                //         value: 'VVVVVVVVVV',
                //         styles: {
                //             color: 'red',
                //             'font-size': '20px',
                //             top: '20%',
                //             left: '30%',
                //         }
                //     }
                // ]
            },
            {
                url: 'https://via.placeholder.com/1080x1920/00FF00/FFFFFF?text=Story+13',
                alt: 'slide 3',
            },
            {
                url: '../../public/assets/background/overlay_2.jpg',
                alt: 'slide 4',
            },

        ]
    },
    // {
    //     "id": "story-1723645806378",
    //     "title": "New Story",
    //     "slides": [
    //         {
    //             "id": "slide-1723645806378",
    //             "url": "",
    //             "overlays": [
    //                 {
    //                     "type": "text",
    //                     "content": "d",
    //                     "color": "#000000",
    //                     "backgroundColor": "#2196f3",
    //                     "fontWeight": "normal",
    //                     "fontSize": 14,
    //                     "position": {
    //                         "x": 0,
    //                         "y": 0
    //                     },
    //                     "size": {
    //                         "width": 25,
    //                         "height": 14
    //                     }
    //                 }
    //             ]
    //         }
    //     ]
    // },
    // {
    //     "id": "story-1723700741792",
    //     "title": "New Story",
    //     "slides": [
    //         {
    //             "id": "slide-1723700741792",
    //             "url": "",
    //             "overlays": [
    //                 {
    //                     "type": "text",
    //                     "content": "ывавыавыаывавыавыавы",
    //                     "color": "#000000",
    //                     "backgroundColor": "#8bc34a",
    //                     "fontWeight": "normal",
    //                     "fontSize": 14,
    //                     "position": {
    //                         "x": -74,
    //                         "y": 168
    //                     },
    //                     "size": {
    //                         "width": 182,
    //                         "height": 14
    //                     }
    //                 }
    //             ]
    //         }
    //     ]
    // }
]
