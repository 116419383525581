import React from "react";
import { Stack, Typography, Container, Button } from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import Lottie from "lottie-react";
import animationData from "../assets/error-auth-animation.json"; // Импортируем анимацию
import { useLocales } from "../locales";
import { useSettingsContext } from "../components/settings";

export default function ErrorAuth() {
    const { t } = useLocales();
    const { tgWebApp } = useSettingsContext();

    const handleCloseApp = () => {
        if (tgWebApp?.close) {
            tgWebApp.close(); // Закрываем приложение
        }
    };

    return (
        <Container maxWidth="xs" sx={{ px: 2, pt: 1, mt: 2, mb: 5 }}>
            <Stack spacing={3} alignItems="center">
                {/* Анимация */}
                <Lottie
                    animationData={animationData}
                    loop
                    autoplay
                    style={{ width: 200, height: 200 }}
                />

                {/* Заголовок */}
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                    Ой! Мы почти показали вам что-то важное, но кажется, интернет-сигнал решил отдохнуть. 😴
                </Typography>

                {/* Описание */}
                <Typography
                    variant="body1"
                    sx={{ textAlign: "center", color: "text.secondary" }}
                >
                    Закройте и откройте приложение — мы уже всё починили!
                </Typography>

                {/* Кнопка "Закрыть" */}
                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handleCloseApp}
                    sx={{ width: "100%", maxWidth: 300 }}
                >
                    Закрыть
                </Button>
            </Stack>
        </Container>
    );
}
