import PropTypes from "prop-types";
import { alpha, useTheme } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import Image from 'src/components/image';
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import React, {useState} from "react";
import {isArray} from "lodash";
import Iconify from "../../components/iconify";
import {paths} from "../../routes/paths";
import {fCurrency} from "../../utils/format-number";
import TextMaxLine from "../../components/text-max-line";
import StoriesComponent from "./stories-component";
import ArticleStoriesWidget from "../article/stories/article-stories-widget";
import {useSettingsContext} from "../../components/settings";
import {setDefaultHeaderColorTg} from "../../utils/color";

import {useSelector} from "../../redux/store";
import { invert } from '../../components/color-invert/color-invert';

export default function UserManagerReviewItem({
                                            item,
                                            sx,
                                            onView ,
                                                  shopid ,
                                            type,
                                                  paper = false , column  , showTitle=true , ratio ,
                                                  ...other
                                        }) {

    const theme = useTheme();
    const navigate = useNavigate();
    const {tgWebApp} = useSettingsContext()

    const [showStories , setShowStories] = useState(false)
    const [storyData , setStoryData] = useState(null)

    const {  current_card } = useSelector((state) => state.lp);

    const handleClick = () =>{

        const arNav = { product : paths.product.details(item.shop_id ,item.id) ,
        article : paths.product.article(item.shop_id , item.id) ,
        category: paths.product.category(shopid, item?.title ||'all') }

        let nav = type === 'product' ? paths.product.details(item.shop_id ,item?.id)
            : paths.product.article(item.shop_id , item.id);
        if(type === 'category') {
            const act = item?.action
            if(act !== 'stories'){
                nav = arNav.category
                navigate(nav);
            }else{
                setStoryData(item?.story?.slides)
                setShowStories(true)
            }
        }else{
            navigate(nav);
        }

    }
    let specialBlock = null

    if(isArray(current_card?.blocks)){
        specialBlock = current_card?.blocks?.find(block => block?.type === 'special_block') || null;
    }

    // const textColorCategory = specialBlock?.content?.useBackgroundColor
    //     ? invert(specialBlock?.content?.backgroundColor, true)
    //     : 'text.primary';



    // Определяем цвет текста для TextMaxLine
    const textColorCategory = type === 'category' && specialBlock?.content?.useBackgroundColor
        ? invert(specialBlock?.content?.backgroundColor, true) // Инвертируем цвет фона special_block
        : 'text.primary'; // Используем цвет по умолчанию

// console.log("showStories" , showStories);
    if(showStories){
        return(
            <ArticleStoriesWidget id={item?.story?.id} onShow={setShowStories} open={showStories}/>
            //     <StoriesComponent
            //         onClose={() => setShowStories(false)}
            //         storyList={storyData}
            //         // stepStories={handleStartStories}
            //         // textLastSlideBtn={t('I understand')}
            //     />
        )
    }

    // if(tgWebApp && !showStories && tgWebApp?.isVersionAtLeast('6.1')){
    //     const defHeaderColor = theme.palette.background.default; // tgWebApp.headerColor;
    //     if(tgWebApp?.headerColor !== theme.palette.background.default){
    //         tgWebApp?.setHeaderColor(defHeaderColor)
    //     }
    // }

    setDefaultHeaderColorTg(tgWebApp , theme.palette.background.default , showStories , true )

    return (
        <Stack
                component={Paper}
                variant="outlined"
                spacing={1}
                alignItems="flex-start"
                sx={{
                    ...(paper && {
                        p:0.8 ,
                    }) ,
                    ...(!paper && {
                        border: 0,
                        bgcolor: 'unset',
                    }) ,
                    maxWidth: 222,
                    // borderRadius: 1,
                    cursor: 'pointer',
                    position: 'relative',

                    ...sx,
                }}
                onClick={handleClick}
                {...other}
            >

                    <Image
                        alt={item.title}
                        src={item?.images?.split(',')[0]}
                        // overlay={alpha(theme.palette.grey[900], 0.48)}
                        sx={{
                            width: 1,
                            borderRadius: 1,
                        }}
                        ratio={ratio}
                    />
            {showTitle &&
                <>
                <Stack direction="row" spacing={1} sx={{px:0.5 , py: 0.5}}>
                    {type !== 'category' &&
                        <Iconify icon="grommet-icons:article" width={16}/>
                    }
                    <TextMaxLine
                        variant="caption"
                        persistent
                        sx={{
                            color: textColorCategory,
                        }}
                    >{item.title}</TextMaxLine>
                    {/* <Typography variant="caption" > */}
                    {/*    {item.title} */}
                    {/* </Typography> */}
                </Stack>
                {type === 'product' &&
                        <Stack direction="row" sx={{px:2.5}} spacing={0.5} >
                            {(!!item.price_sale>0) && (
                                <Typography color="primary" variant="caption" sx={{ color: 'text.disabled', textDecoration: 'line-through' }}> {fCurrency(item.price_sale)}</Typography>
                            )}
                            <Typography color="primary" variant="caption">{fCurrency(item.price)}₽</Typography>
                        </Stack>
                }
                </>
            }

            </Stack>


    );
}

UserManagerReviewItem.propTypes = {
    item: PropTypes.object,
    sx: PropTypes.object,
    onView: PropTypes.func ,
    type: PropTypes.string ,
    paper: PropTypes.bool ,
    column: PropTypes.number ,
    showTitle: PropTypes.bool,
    ratio: PropTypes.string,
    shopid: PropTypes.string ,
};
