import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import {getContrastTextColor} from "../../utils/color";

// ----------------------------------------------------------------------
// theme.palette.grey[500]
/**
 * Рассчитывает контрастный цвет текста на основе цвета фона.
 * @param {string} backgroundColor - Цвет фона в формате HEX или RGB.
 * @returns {string} - Контрастный цвет текста (#000000 или #ffffff).
 */


export default function ComponentBlock({ title, sx, children,
                                           backgroundColor ,
                                           borderType = 'solid',
                                           textColor,
                                           backgroundOpacity = 1 ,
                                           ...other }) {
    // Рассчитываем контрастный цвет текста, если он не указан
    const contrastTextColor = textColor || getContrastTextColor(backgroundColor);

    // Рассчитываем цвет фона с прозрачностью
    const backgroundWithOpacity = backgroundColor
        ? alpha(backgroundColor, backgroundOpacity)
        : undefined;

  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: 1.5,
          borderStyle: borderType,
          backgroundColor: backgroundWithOpacity || ((theme) => alpha(theme.palette.grey[600], 0.04)), // Используем значение по умолчанию, если фон не выбран
          color: contrastTextColor, // Автоматический контрастный цвет текста
          ...sx,
      }}
    >
        {title && <CardHeader title={title} sx={{ p: 1.5, color: contrastTextColor }} />}

      <Stack
        spacing={2}
        // direction="row"
        // alignItems="center"
        // justifyContent="center"
        // flexWrap="wrap"
        sx={{
          p: 2,
          minHeight: 180,
            color: contrastTextColor,
          ...sx,
        }}
        {...other}
      >
        {children}
      </Stack>
    </Paper>
  );
}

ComponentBlock.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
  title: PropTypes.string,
  backgroundColor: PropTypes.string,
    backgroundOpacity: PropTypes.number,
  borderType: PropTypes.oneOf(['none', 'dashed', 'dotted', 'solid', 'double']),
  textColor: PropTypes.string,
};
