import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import { Box, Card, CardMedia, Paper, Stack, Typography, Button, Divider } from '@mui/material'
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"

import Iconify from 'src/components/iconify'
import { useTheme, alpha } from "@mui/material/styles"

import { FastAverageColor } from 'fast-average-color'

// import { _mock } from "../../_mock/_mock—копия"
import { invert, invertAsRGB, invertAsRgbArray } from '../../components/color-invert/color-invert'
import { isTgLink, selfLink } from "../../utils/article-self-link"
import { useRouter } from "../../routes/hooks"
import { useSettingsContext } from "../../components/settings"
import { useSelector } from "../../redux/store"

// import './css/wave.css'


export default function UserCardTop({ user, settings, enableButtonLockingTop = 'false' }) {
    const { tgWebApp } = useSettingsContext()
    const { curShopId } = useSelector((state) => state.startform)

    const router = useRouter()
    const paperRef = useRef(null)
    const theme = useTheme()
    const [isFixed, setIsFixed] = useState(false)
    const initialOffsetTop = useRef(0)

    // const [calculatedTextColor, setCalculatedTextColor] = useState('#FFFFFF')
    const [calculatedTextColor, setCalculatedTextColor] = useState({
        title: "#FFFFFF",
        subtitle: "#FFFFFF",
        subtitle2: "#FFFFFF",
        subText: "#FFFFFF",

    })
    // const [highlightedTextColor, setHighlightedTextColor] = useState('#FFEDC4')

    const cover = settings?.imagine
    // if (settings && settings?.useImagine) {
    //     cover = user?.avatar || _mock.image.cover(getRandomInt(5))
    // }
    // console.log('calculatedTextColor', calculatedTextColor)
    // console.log('settings settings', settings)

    const listRef = useRef(null)
    const [listHeight, setListHeight] = useState(0)

    useEffect(() => {
        // Функция для обновления высоты списка
        const updateListHeight = () => {
            if (listRef.current) {
                setListHeight(listRef.current.offsetHeight) // Получаем высоту списка
            }
        }

        // Вызываем при монтировании и изменении
        updateListHeight()

        // Добавляем слушатель изменения размеров (на случай изменения контента списка)
        window.addEventListener('resize', updateListHeight)
        return () => {
            window.removeEventListener('resize', updateListHeight) // Убираем слушатель при размонтировании
        }
    }, [settings?.bannerText?.subText])

    // const isGradient = settings?.gradientStartColor && settings?.gradientEndColor && !settings?.useImagine
    const isGradient = settings?.useBackgroundColor && settings?.gradientStartColor && settings?.gradientEndColor && !settings?.useImagine

    useEffect(() => {
        const fac = new FastAverageColor()


        const updateColors = async () => {
            const bannerText = settings?.bannerText || {}

            const getTextColor = async (fieldSettings, fallbackColor) => {
                if (fieldSettings?.useBackground) {
                    // Если есть подложка, выбираем цвет относительно подложки
                    if (fieldSettings?.useTextColor) {
                        // Используем заданный цвет текста
                        return fieldSettings.textColor || "#000000"
                    }
                    const bgColor = fieldSettings.backgroundColor || "#FFFFFF"
                    return invert(bgColor, true) // Инвертируем цвет подложки
                    // return `${invert(bgColor, true)}, ${opacity}`
                }

                if (fieldSettings?.useTextColor) {
                    // Если задан цвет текста, используем его
                    return fieldSettings.textColor || "#000000"
                }

                if (settings?.useImagine) {
                    // Если используется картинка, определяем цвет текста на основе изображения
                    try {
                        const color = await fac.getColorAsync(cover)
                        return invert(color.hex || fallbackColor, true)
                    } catch (error) {
                        console.log("Error getting average color from image:", error)
                        return fallbackColor
                    }
                }

                // Если градиент включен, используем первый цвет градиента для определения цвета текста
                if (settings?.useBackgroundColor) {
                    return invert(settings?.gradientStartColor || fallbackColor, true)
                }

                // Если градиент выключен, подложки нет, цвет текста не задан, используем primary
                return ('primary')
                // return fallbackColor;

            }

            // Определяем цвета для каждого текстового поля
            const titleColor = await getTextColor(bannerText?.title, "#primary")
            const subtitleColor = await getTextColor(bannerText?.subtitle, "#primary")
            const subtitle2Color = await getTextColor(bannerText?.subtitle2, "#primary")
            const subTextColor = await getTextColor(bannerText?.listSettings, "#primary")


            // Устанавливаем определённые цвета
            setCalculatedTextColor({
                title: titleColor,
                subtitle: subtitleColor,
                subtitle2: subtitle2Color,
                subText: subTextColor,

            })
        }
        updateColors()
    }, [cover, isGradient, settings])

    const justifyContentPosition = (() => {
        if (settings?.bannerText?.textPosition.value === 'bottom') return 'flex-end'
        if (settings?.bannerText?.textPosition.value === 'center') return 'center'
        if (settings?.bannerText?.textPosition.value === 'top') return 'flex-start'
        return 'flex-start'
    })()
    const justifyContentPositionHorizontal = (() => {
        if (settings?.bannerText?.textPosition.horizontal === 'right') return 'end'
        if (settings?.bannerText?.textPosition.horizontal === 'center') return 'center'
        if (settings?.bannerText?.textPosition.horizontal === 'left') return 'start'

        return 'center'
    })()

    const horizontalPositionStyles = (() => {
        if (settings?.buttonSetting?.horizontalPosition === 'right') return { left: 'auto', right: 16, }
        if (settings?.buttonSetting?.horizontalPosition === 'left') return { left: 16, right: 'auto', }
        // if (settings?.buttonSetting?.horizontalPosition === 'center') return { }

        return { left: 'auto', }
    })()

    const [fixedStyles, setFixedStyles] = useState({
        position: 'absolute',
        top: 'auto',
        bottom: 0,
        // left: 'auto',
        ...horizontalPositionStyles,
        width: 'auto',
        zIndex: 'auto',
        transform: settings?.moveButtonsInside ? 'translateY(-30%)' : 'translateY(50%)',

    })

    useEffect(() => {
        if (enableButtonLockingTop === 'false' || !settings?.buttonSetting?.lockButtonScrolling) return

        initialOffsetTop.current = paperRef.current.getBoundingClientRect().top + window.scrollY - 8
        initialOffsetTop.current = paperRef.current.getBoundingClientRect().top + window.scrollY - 8

        const horizontalPositionStylesFixed = (() => {
            if (settings?.buttonSetting?.horizontalPosition === 'right') return { transform: 'translateX(0%)' }
            if (settings?.buttonSetting?.horizontalPosition === 'left') return { transform: 'translateX(0%)' }

            return { transform: 'translateX(50%)' }
        })()

        const updateFixedStyles = (fixed) => {
            if (fixed) {
                setFixedStyles({
                    position: 'fixed',
                    top: '8px',
                    // left: paperRef.current.getBoundingClientRect().left,
                    width: `${paperRef.current.getBoundingClientRect().width}px`,
                    zIndex: 1302,
                    ...horizontalPositionStylesFixed
                })
            } else {
                setFixedStyles({
                    position: 'absolute',
                    top: 'auto',
                    bottom: settings?.moveButtonsInside ? 16 : 0,
                    ...horizontalPositionStyles,
                    width: 'auto',
                    zIndex: 'auto',
                    transform: settings?.moveButtonsInside ? 'translateY(0%)' : 'translateY(50%)',
                })
            }
        }

        const handleScroll = () => {
            const scrollTop = window.scrollY || document.documentElement.scrollTop
            // console.log('isFixed scrollTop', scrollTop)

            // Фиксируем элемент, когда его верхняя часть достигает верхней границы окна
            if (scrollTop >= initialOffsetTop.current && !isFixed) {
                // setFixedWidth(`${paperRef.current.offsetWidth}px`)
                setIsFixed(true)
                updateFixedStyles(true)
            }
            // Возвращаем элемент в исходное положение
            else if (scrollTop < initialOffsetTop.current && isFixed) {
                setIsFixed(false)
                updateFixedStyles(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        handleScroll()

        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [enableButtonLockingTop, horizontalPositionStyles, isFixed, settings?.buttonSetting?.horizontalPosition, settings?.buttonSetting?.lockButtonScrolling, settings?.moveButtonsInside])


    const handleClick = (link) => {
        const lnk = selfLink(link, curShopId)
        // console.log("LNK", lnk)
        if (lnk?.type === 'router') {
            router.push(lnk?.link)
        } else if (lnk?.type === 'location') {
            if (isTgLink(lnk?.link)) tgWebApp?.openTelegramLink(lnk?.link)
            else window.location.href = lnk?.link
        }
    }

    useEffect(() => {
        setFixedStyles((prevStyles) => ({
            ...prevStyles,
            transform: settings?.moveButtonsInside ? 'translateY(-30%)' : 'translateY(50%)',
        }))
    }, [settings.moveButtonsInside])


    const paperHeight = paperRef?.current?.offsetHeight || 0

    const marginTextBox = (() => {
        if (settings?.bannerText?.listSettings?.stickToBottom) {
            if (settings?.bannerText?.subText?.length > 0) {
                return settings?.moveButtonsInside
                    ? (paperHeight + 2 * (paperHeight * 0.3) + listHeight) / 8
                    : (paperHeight * 0.5 + listHeight) / 8
            }
            if (settings?.bannerText?.subText?.length === 0) {
                return settings?.moveButtonsInside
                    ? (paperHeight + 2 * (paperHeight * 0.3)) / 8
                    : (paperHeight * 0.5) / 8
            }
            return 2

        }
        if (settings?.bannerText?.subText?.length === 0) {
            return settings?.moveButtonsInside
                ? (paperHeight + 2 * (paperHeight * 0.3)) / 8
                : (paperHeight * 0.5) / 8
        }
        return 0
    })()

    const backgroundButton = `rgba(${hexToRgb(settings?.buttonSetting?.backgroundColor || '#FFFFFF')},
                                            ${(settings?.buttonSetting?.backgroundColor
            ? (settings?.buttonSetting?.backgroundOpacity ?? 100) / 100
            : 1)})`
    const textColorButton = `rgba(${hexToRgb(settings?.buttonSetting?.textColor || '#000000')},
                                        ${(settings?.buttonSetting?.textColor
            ? (settings?.buttonSetting?.textOpacity ?? 100) / 100
            : 1)})`




    // eslint-disable-next-line react/no-unstable-nested-components
    const PaperContent = () =>


    (
        <Paper
            elevation={2}
            ref={paperRef}
            sx={{
                ...fixedStyles,
                transition: 'top 0.3s ease',
                ...horizontalPositionStyles,
                // backgroundColor: settings?.buttonSetting?.useBackgroundColor
                //     ? settings?.buttonSetting?.backgroundColor
                //     : "",

                backgroundColor: settings?.buttonSetting?.useBackgroundColor
                    ? backgroundButton
                    : "",

            }}
        >
            {settings?.buttonCount > 0 && (
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    divider={<Divider orientation="vertical" flexItem />}
                    sx={{
                        borderRadius: 1,
                        zIndex: 1302,

                    }}
                >
                    {settings?.buttonTexts?.map((button, index) => (
                        <Button
                            key={index}
                            variant='text'
                            // href={button.url}
                            onClick={() => handleClick(button.url)}
                            sx={{
                                fontWeight: 'bold',
                                padding: '2',
                                width: '100%',
                                minWidth: '100px',
                                zIndex: 1302,
                                color: settings?.buttonSetting?.useTextColor
                                    ? textColorButton
                                    : "#000000",
                                // '&:hover': {
                                // backgroundColor: theme.palette.background.default,
                                // },
                            }}
                        >
                            {button.text}
                        </Button>
                    ))}
                </Stack>
            )}
        </Paper>
    )


    return (
        <Card

            sx={{
                position: 'relative',
                display: 'flex',
                overflow: 'visible',
                width: '100%',
                aspectRatio: settings?.aspectRatio,
                height: '100%',
                // borderRadius: '0',
                // backgroundImage: settings?.useImagine
                //     ? settings.imagine
                //     : `linear-gradient(${
                //         typeof settings?.gradient_deg === 'string'
                //             ? settings.gradient_deg.replace(/[^0-9.]/g, '') // Убираем всё, кроме цифр и точки
                //             : settings?.gradient_deg || 120
                //     }deg, ${settings?.gradientStartColor}, ${settings?.gradientEndColor})`,

                // eslint-disable-next-line no-nested-ternary
                backgroundImage: settings?.useImagine
                                ? settings?.imagine
                                : isGradient
                                    ? `linear-gradient(${typeof settings?.gradient_deg === 'string'
                                        ? settings?.gradient_deg.replace(/[^0-9.]/g, '')// Убираем всё, кроме цифр и точки
                                        : settings?.gradient_deg || 120
                                    }deg, ${settings?.gradientStartColor}, ${settings?.gradientEndColor})`
                                    : 'none',
                
                // backgroundColor:isGradient ? `linear-gradient(${typeof settings?.gradient_deg === 'string'
                //                     ? settings?.gradient_deg.replace(/[^0-9.]/g, '')// Убираем всё, кроме цифр и точки
                //                     : settings?.gradient_deg || 120
                //                 }deg, ${settings?.gradientStartColor}, ${settings?.gradientEndColor})`
                //                 : 'none',

                // eslint-disable-next-line object-shorthand
                borderRadius: settings?.borderRadius || 0,
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    border: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                }}
            >
                {settings?.useImagine ? (
                    <CardMedia
                        component="img"
                        image={settings?.imagine}
                        title="Event Cover"
                        overlay={alpha(theme.palette.grey[900], 0)}

                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            bottom: 0,
                            border: 'none',
                            borderRadius: settings?.borderRadius || 0,
                        }}
                    />
                ) : null }
            </Box>
            <Box
                sx={{
                    width: '100%',
                    paddingX: 2,
                    paddingTop: 2,
                    textAlign: 'center',
                    zIndex: 2,
                    // color: calculatedTextColor?.subtitle2 || "black",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: justifyContentPosition,
                    // paddingBottom: 2,
                    position: 'relative',

                    // paddingBottom: settings?.listSettings?.stickToBottom ? 4 : 0,

                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: justifyContentPositionHorizontal,

                        mb: marginTextBox,
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                            borderRadius: '4px',
                            textAlign: justifyContentPositionHorizontal,
                            fontWeight: settings?.bannerText?.subtitle2?.fontStyle || 'normal',
                            fontSize: settings?.bannerText?.subtitle2?.fontSize,
                            paddingX: settings?.bannerText?.subtitle2?.useBackground ? 2 : "none",
                            color: calculatedTextColor?.subtitle2 || "black",
                            opacity: (settings?.bannerText?.subtitle2?.textOpacity || 100) / 100,

                            // backgroundColor: settings?.bannerText?.subtitle2?.useBackground
                            //     ? alpha(settings?.bannerText?.subtitle2?.backgroundColor || '#ffffff', settings?.bannerText?.subtitle2?.opacity || 1)
                            //     : "none",
                            backgroundColor: settings.bannerText?.subtitle2?.useBackground
                                ? alpha(settings?.bannerText?.subtitle2?.backgroundColor || '#ffffff', (settings?.bannerText?.subtitle2?.backgroundOpacity || 100) / 100)
                                : "none",

                        }}>
                        {settings?.bannerText?.subtitle2?.value}
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: justifyContentPositionHorizontal,
                            // backgroundColor: settings.bannerText?.subtitle?.useBackground
                            //     ? alpha(settings?.bannerText?.subtitle?.backgroundColor || '#ffffff' , settings?.bannerText?.subtitle?.opacity || 1)
                            //     : "none",
                            backgroundColor: settings.bannerText?.subtitle?.useBackground
                                ? alpha(settings?.bannerText?.subtitle?.backgroundColor || '#ffffff', (settings?.bannerText?.subtitle?.backgroundOpacity || 100) / 100)
                                : "none",

                            color: calculatedTextColor.subtitle || "black",
                            opacity: (settings?.bannerText?.subtitle?.textOpacity || 100) / 100,

                            paddingX: settings.bannerText?.subtitle?.useBackground ? 2 : "none",
                            marginTop: 1,
                            marginBottom: 1,
                            borderRadius: '4px',
                            display: 'inline-block',
                            wordBreak: 'break-word',
                            fontWeight: settings.bannerText?.subtitle?.fontStyle || 'normal',
                            fontSize: settings.bannerText?.subtitle?.fontSize,
                        }}
                    >
                        {settings?.bannerText?.subtitle?.value}
                    </Typography>


                    <Typography
                        variant="h5"
                        sx={{
                            // textTransform: 'uppercase',
                            textAlign: justifyContentPositionHorizontal,
                            // backgroundColor: settings.bannerText?.title?.useBackground
                            //     ? settings.bannerText?.title?.backgroundColor
                            //     : "none",
                            backgroundColor: settings.bannerText?.title?.useBackground
                                ? alpha(settings?.bannerText?.title?.backgroundColor || '#ffffff', (settings?.bannerText?.title?.backgroundOpacity || 100) / 100)
                                : "none",
                            paddingX: settings.bannerText?.title?.useBackground ? 2 : "none",
                            paddingY: 0.5,
                            marginBottom: 1,
                            borderRadius: '4px',
                            color: calculatedTextColor.title,
                            opacity: (settings?.bannerText?.title?.textOpacity || 100) / 100,
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                            // fontStyle: settings.bannerText?.title?.fontStyle || 'normal',
                            fontWeight: settings.bannerText?.title?.fontStyle || 'normal',
                            fontSize: settings.bannerText?.title?.fontSize,

                        }}
                    >
                        {/* {settings.bannerText.title?.value} <span style={{ color: calculatedTextColor || '#FFEDC4', }}>{settings.bannerText.highlightedText?.value}</span> */}
                        {settings.bannerText?.title?.value}
                    </Typography>

                </Box>


                {settings?.bannerText?.subText.length > 0 && (
                    <Box
                        ref={listRef}
                        sx={{
                            width: settings?.bannerText?.listSettings?.stickToBottom ? 'calc(100% - 32px)' : '100%',
                            // position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            position: settings?.bannerText?.listSettings?.stickToBottom ? 'absolute' : 'relative',
                            mb: settings?.moveButtonsInside ? (paperHeight + 2 * (paperHeight * 0.3)) / 8 : 2.25,
                            bottom: settings?.bannerText?.listSettings?.stickToBottom ? 0 : 'auto',
                            backgroundColor: settings?.bannerText?.listSettings?.useBackgroundList
                                ? alpha(settings?.bannerText?.listSettings?.backgroundColor || '#000000', (settings?.bannerText?.listSettings?.backgroundOpacity || 100) / 100)
                                : 'none',
                            borderRadius: 0.5,
                            color: calculatedTextColor?.subText || "black",
                        }}
                    >
                        <List sx={{ py: 0.5 }}>
                            {settings?.bannerText?.subText.map((item, index) => (
                                <ListItem key={index} sx={{ display: "flex", alignItems: "center", px: 1, justifyContent: justifyContentPositionHorizontal }}>
                                    <ListItemIcon sx={{ mr: 1, mt: 0.5 }}>
                                        {/* <Iconify icon={item.icon} /> */}
                                        {item.icon.startsWith('<?xml') ? (
                                            // Если icon — это SVG-код, используем dangerouslySetInnerHTML для вставки SVG
                                            <span
                                                style={{
                                                    display: 'inline-block',
                                                    width: '15px',
                                                    height: '15px',
                                                }}
                                                dangerouslySetInnerHTML={{ __html: item.icon }}
                                            />
                                        ) : (
                                            // Если icon — это имя иконки, используем компонент Iconify
                                            <Iconify icon={item.icon} />
                                        )}

                                        {/* {console.log('icon',<Iconify icon={item.icon ? item.icon : 'fluent-emoji-high-contrast:red-question-mark'} />)} */}
                                    </ListItemIcon>
                                    <Typography variant="caption"
                                        sx={{
                                            wordBreak: 'break-word',
                                            lineHeight: 1,
                                            // color: theme.palette.warning.lighter,
                                            // color: calculatedTextColor?.subText || "black",
                                        }}>
                                        {item.text}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}

                {isFixed
                    ? ReactDOM.createPortal(<PaperContent />, document.body)
                    : <PaperContent />
                }


            </Box>
        </Card>
    )
}


UserCardTop.propTypes = {
    user: PropTypes.object,
    settings: PropTypes.object,
    enableButtonLockingTop: PropTypes.bool,
}

const hexToRgb = (hex) => {
    // console.log('hexhex', hex)
    if (!hex || typeof hex !== "string") return "0, 0, 0"

    let r = 0; let g = 0; let b = 0
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16)
        g = parseInt(hex[2] + hex[2], 16)
        b = parseInt(hex[3] + hex[3], 16)
    } else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16)
        g = parseInt(hex[3] + hex[4], 16)
        b = parseInt(hex[5] + hex[6], 16)
    }
    return `${r}, ${g}, ${b}`
}
