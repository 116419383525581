import PropTypes from "prop-types";
import React, {useCallback, useEffect, useState} from "react";
import {Box, CircularProgress} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Confetti from "react-confetti-boom";
import ArticleForm from "../../../components/article-form";
import {useDispatch, useSelector} from "../../../redux/store";
import {getAnswersFormId, getFormID} from "../../../redux/slices/forms";
import Iconify from "../../../components/iconify";
import ArticleQuizForm from "../../../components/article-form/article-quiz-form";
import Markdown from "../../../components/markdown";
import {useLocales} from "../../../locales";
import ArticlePointsSwipeDrawerDrawer from "../article-points-swipe-drawer";


export default function ArticleFormView({
                                            formId ,
                                            tgID , tgUser ,
                                            borderType,
                                            backgroundColor,
                                            backgroundOpacity ,
                                            sx}){

    const {t} = useLocales();

    const dispatch = useDispatch();
    const [showForm , setShowForm] = useState(false);

    const {  curShopId  } = useSelector((state) => state.startform);
    const {  isLoading , forms , current_form , current_forms} = useSelector((state) => state.forms);

    const [completeForm , setComplete] = useState({show: false});
    const [showDrawer , setShowDrawer] = useState(false)

    useEffect(() => {
        // console.log("formID usef" , formId);
        dispatch(getFormID(curShopId  , formId , tgID));
        // dispatch(getAnswersFormId(curShopId  , formId , tgID));
    }, [curShopId, dispatch, formId, tgID  ]);

    const currentForm = current_forms.find(el => el.id === formId)

    const fType = currentForm?.fields?.form_type || currentForm?.form_type ;
    const pointsForFilling = currentForm?.points_for_filling || currentForm?.fields?.points ;
    const _points = pointsForFilling || 0


    const handleSetComplete = useCallback((val) => {

        const show = val?.show && _points > 0
        setComplete({...val , show})
    } , [_points])



    const handleCloseSwipeDrawer = () => {
        setComplete({show: false})
    }

    const regualarForm = fType === 'regular_form' || fType === 'key_form'

    return(
        <>
        <Box sx={sx}>

            {isLoading && <CircularProgress />}
             { (!isLoading && currentForm  ) &&
                 <>
                     {regualarForm &&
                         <ArticleForm
                             form={currentForm}
                             tgID={tgID}
                             tgUser={tgUser}
                             onComplete={handleSetComplete}
                             borderType={borderType}
                             backgroundColor={backgroundColor}
                             backgroundOpacity={backgroundOpacity}
                         />
                     }
                     {fType === 'quiz_form' &&
                         <ArticleQuizForm form={currentForm} tgID={tgID} tgUser={tgUser} onComplete={handleSetComplete}/>
                     }
                 </>
             }

       </Box>
            {(_points > 0 && !currentForm?.need_check) &&
                <ArticlePointsSwipeDrawerDrawer text={_points} open={completeForm?.show} onClose={handleCloseSwipeDrawer}/>
            }
       </>
    );
}

ArticleFormView.propTypes = {
    formId: PropTypes.string ,
    tgID: PropTypes.number ,
    tgUser: PropTypes.object ,
    sx: PropTypes.object,
    borderType: PropTypes.string ,
    backgroundColor : PropTypes.string ,
    backgroundOpacity: PropTypes.number,
    // current_form: PropTypes.object ,
    // isLoading: PropTypes.bool ,
}
