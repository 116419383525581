import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";

export function PaymentItemSkeleton({ sx, ...other }) {
    return (
        <>
        <Stack sx={{ p: 3 }} spacing={2}>
            <Paper
                variant="outlined"
                sx={{
                    borderRadius: 2,
                    ...sx,
                }}
                {...other}
            >

                    {/* <Skeleton sx={{ paddingTop: '100%' }} /> */}
                    <Skeleton  sx={{ width: '100%', height: 90 }} />


                {/* <Stack spacing={2} sx={{ p: 3, pt: 2 }}> */}
                {/*    <Skeleton sx={{ width: 0.5, height: 16 }} /> */}
                {/*    <Stack direction="row" justifyContent="space-between" alignItems="center"> */}
                {/*        <Stack direction="row"> */}
                {/*            <Skeleton variant="circular" sx={{ width: 16, height: 16 }} /> */}
                {/*            <Skeleton variant="circular" sx={{ width: 16, height: 16 }} /> */}
                {/*            <Skeleton variant="circular" sx={{ width: 16, height: 16 }} /> */}
                {/*        </Stack> */}
                {/*        <Skeleton sx={{ width: 40, height: 16 }} /> */}
                {/*    </Stack> */}
                {/* </Stack> */}
            </Paper>
            <Paper
                variant="outlined"
                sx={{
                    borderRadius: 2,
                    ...sx,
                }}
                {...other}
            >
                <Skeleton  sx={{ width: '100%', height: 90 }} />
            </Paper>
            <Paper
                variant="outlined"
                sx={{
                    borderRadius: 2,
                    ...sx,
                }}
                {...other}
            >
                <Skeleton  sx={{ width: '100%', height: 90 }} />
            </Paper>
        </Stack>
        </>

    );
}

PaymentItemSkeleton.propTypes = {
    sx: PropTypes.object,
};
