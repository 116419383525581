import ReactPlayer from "react-player/youtube";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import EmptyContent from "../empty-content";
import VkPlayer from "./vk-player";

ResponsivePlayer.propTypes = {
    // config: PropTypes.object,
    url: PropTypes.string,
    light: PropTypes.bool ,
    onPreview: PropTypes.func ,
    disabled: PropTypes.bool ,
    type: PropTypes.string ,
    embed: PropTypes.bool,
    // sx: PropTypes.object,
};

const arType = ['yacloud' , 'dzen' , 'rutube' , 'youtube' , 'plvideo' , 'vk' , 'vkclip']

export default function ResponsivePlayer ({url , light , onPreview , disabled , type='youtube' , embed }) {

    let vkVideo = url?.includes('vk.com/video')
    let vkVideoClip = url?.includes('vk.com/clip')
    let rutubeVideo = url?.includes('rutube.ru/play')
    let youtube = url?.includes('youtube.com')
    let dzen = url?.includes('dzen.ru/embed')
    let ya = url?.includes('video.cloud.yandex.net/player')
    let plvideo = url?.includes('/plvideo.ru/embed');

    if(embed && arType.includes(type)){
        vkVideo = type === 'vk';
        vkVideoClip = type === 'vkclip';
        rutubeVideo = type === 'rutube';
        youtube = type === 'youtube';
        dzen = type === 'dzen';
        ya = type === 'yacloud';
        plvideo = type === 'plvideo';
    }


    const renderEmpty = (
        <EmptyContent
            filled
            title="Видео не доступно"

        />
    );
    return (
        <>
        {url &&
        <Box sx={{
            position: "relative" ,
            ...(!vkVideoClip && {
                paddingTop: "56.25%" , /* Player ratio: 100 / (1280 / 720) */
            }) ,
            ...(disabled && {
                opacity: 0.48,
                filter: 'grayscale(1)',
            })
        }}
        >
            {vkVideo  &&
                <VkPlayer url={url} onClickPreview={onPreview}
                          disabled={disabled} type="vk"/>
            }
            {vkVideoClip &&
                <VkPlayer url={url} onClickPreview={onPreview}
                          disabled={disabled} type="vkclip" responsive={false}/>
            }
            {rutubeVideo &&
                <VkPlayer url={url} onClickPreview={onPreview}
                          disabled={disabled} type="rutube"/>
            }
            {dzen &&
                <VkPlayer url={url} onClickPreview={onPreview}
                          disabled={disabled} type="dzen"/>
            }

            {ya &&
                <VkPlayer url={url} onClickPreview={onPreview}
                          disabled={disabled} type="ya"/>
            }

            {plvideo &&
                <VkPlayer url={url} onClickPreview={onPreview}
                          disabled={disabled} type="plvideo"/>
            }

            {youtube &&
                <ReactPlayer
                    style={{position: "absolute",
                        top: 0 ,
                        left: 0}}
                    url={url}
                    width='100%'
                    height='100%'
                    light={light}
                    muted
                    onClickPreview={onPreview}
                    playing={!disabled}
                    controls={!disabled}
                    config={{
                        youtube: {
                            playerVars: { showinfo: 0 , rel: 0 , fs: 1 ,loop: 1 , controls: 2 , playsinline: 1 , autoplay: 0 }
                        },
                    }}
                />
            }


        </Box>
        }
        {!url && renderEmpty}
        </>
    )
}
