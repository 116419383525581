import PropTypes from "prop-types"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import FormProvider, {
    RHFSelect,
    RHFSwitch,
    RHFTextField,
    RHFMultiSelect,
    RHFMultiCheckbox, RHFRadioGroup,
} from 'src/components/hook-form'
import React, { useCallback, useEffect, useMemo, useState } from "react"

import * as Yup from "yup"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

import dayjs from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import 'dayjs/locale/ru'

import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import Alert from "@mui/material/Alert"
import "yup-phone"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel, { formControlLabelClasses } from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import LinearProgress from "@mui/material/LinearProgress";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import {useTheme} from "@mui/material/styles";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import ComponentBlock from "./component-block"

import { useRouter } from "../../routes/hooks"
import { useLocales } from "../../locales"
import axios from "../../utils/axios"
import { useDispatch, useSelector } from "../../redux/store"
import { useSnackbar } from "../snackbar"
// import { getFormID } from "../../redux/slices/forms"
import Iconify from "../iconify"
import RHFPhoneInput from "../hook-form/rhf-phone-input"
import Markdown from "../markdown"
// import QuizList from "../../sections/quiz/quiz-list"
// import { setCurrentLead } from "../../redux/slices/lp"
// import QuizListForm from "./quiz/quiz-list-from"
// import QuizFormNode from "./quiz/quiz-form-node";
import {PaymentItemSkeleton} from "../../sections/payment/PaymentItemSkeleton";
import {fDate} from "../../utils/format-time";
import RHFPhoneTextfield from "../hook-form/rhf-phone-textfield";




export default function ArticleQuizForm({ form, tgID, tgUser , onComplete }) {
    const router = useRouter()
    const { t } = useLocales()
    const dispatch = useDispatch()
    const theme = useTheme()

    const formFields = form?.fields
    const { curShopId } = useSelector((state) => state.startform)
    // const { current_quiz, error, isLoading, lead } = useSelector((state) => state.lp)

    const { enqueueSnackbar } = useSnackbar()

    const [locale, setLocale] = useState('ru')
    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState(form?.complete || false)
    const [phone, setPhone] = useState('')
    const [checkedPolicy, setCheckedPolicy] = useState(true)
    const [nodeElements , setNodeElements] = useState(null);
    const [activeStep , setActiveStep] = useState(0)

    const handleChangePolicy = (event) => {
        setCheckedPolicy(event.target.checked)
    }


    // const complete = form?.complete || false ;
    let infoForm = {}

    formFields?.questions.forEach((el, index) => {
        const retObj = {}
        if (el.type === 'list of list') infoForm = { ...infoForm, [`checkbox_${index}`]: { title: el.title, items: el.options } }
        if (el.type === 'one of list') infoForm = { ...infoForm, [`radio_${index}`]: { title: el.title, items: el.options } }
        if (el.type === 'text') infoForm = { ...infoForm, [`text_${index}`]: { title: el.title, items: el.options } }
        if (el.type === 'phone') infoForm = { ...infoForm, [`phone_${index}`]: { title: el.title, items: el.options } }
        if (el.type === 'email') infoForm = { ...infoForm, [`email_${index}`]: { title: el.title, items: el.options } }
        if (el.type === 'verny_otvet') infoForm = { ...infoForm, [`qa_${index}`]: { title: el.title, items: el.options } }
        if (el.type === 'correct_answer') infoForm = { ...infoForm, [`qa_${index}`]: { title: el.title, items: el.options } }
        if (el.type === 'datetime') infoForm = { ...infoForm, [`date_${index}`]: { title: el.title, items: el.options , type: el?.type , category: el?.category} }
    })


    // console.log("DEFF" , infoForm);

    const setDefault = useCallback((act = 'default') => {
        let def = {}
        let yupSchema = {}
        formFields?.questions.forEach((el, index) => {
            const retObj = {}
            // console.log('el', el)
            if (el.type === 'list of list') {
                def = { ...def, [`checkbox_${index}`]: [] }
                if (el.required) {
                    yupSchema = { ...yupSchema, [`checkbox_${index}`]: Yup.array().min(1, `${t('form.required')}`) }
                }
            }
            if (el.type === 'one of list') {
                def = { ...def, [`radio_${index}`]: '' }
                if (el.required) {
                    yupSchema = { ...yupSchema, [`radio_${index}`]: Yup.string().required(`${t('form.required')}`) }
                }
            }
            if (el.type === 'text') {
                def = { ...def, [`text_${index}`]: '' }
                if (el.required) {
                    yupSchema = { ...yupSchema, [`text_${index}`]: Yup.string().required(`${t('form.required')}`) }
                }
            }
            // if (el.type === 'phone') {
            //     def = { ...def, [`phone_${index}`]: '' }
            //     const phoneRegExp = /^(?<code>\+?\d{1,3})[-\s]{0,}(?<number>\(?\d{3}\)?[-\s]{0,}\d{3}[-\s]{0,}\d{2}[-\s]{0,}\d{2})$/gm
            //     if (!el.required) {
            //         yupSchema = { ...yupSchema, [`phone_${index}`]: Yup.string().matches(phoneRegExp, `${t('form.invalid_phone_format')}`).required(`${t('form.required')}`) }
            //     }
            // }
            if (el.type === 'phone') {
                // Добавляем поле в объект значений по умолчанию
                def = { ...def, [`phone_${index}`]: '' };

                // Создаём схему валидации для поля с использованием libphonenumber-js
                const phoneValidation = Yup.string()
                    .required(t('form.required'))
                    .transform((value) =>
                        // Удаляем все символы, кроме цифр и +
                        value.replace(/[^\d+]/g, '')
                    )
                    .test('is-valid-phone', t('form.invalid_phone_format'), (value) => {
                        if (!value) return false;

                        // Парсим номер телефона
                        const phoneNumber = parsePhoneNumberFromString(value, 'RU');
                        return phoneNumber?.isValid() || false;
                    });

                // Добавляем валидацию в схему
                yupSchema = {
                    ...yupSchema,
                    [`phone_${index}`]: el.required ? phoneValidation : phoneValidation.notRequired(),
                };
            }
            if (el.type === 'email') {
                def = { ...def, [`email_${index}`]: '' }
                const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                if (!el.required) {
                    // yup.string().email().matches(/^(?!.*@[^,]*,)/)
                    yupSchema = { ...yupSchema, [`email_${index}`]: Yup.string().trim().required(`${t('form.required')}`).email(`${t('form.invalid_email_format')}`).matches(emailRegex, `${t('form.invalid_email_format')}`) }
                }
            }
            // ^(\+)?((\d{2,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){5,12}\d$
            // verny_otvet
            if (el.type === 'verny_otvet') {
                def = { ...def, [`qa_${index}`]: '' }

                if (el.options) {
                    // const regex = `/(${el?.options})/i`
                    let arSearch = []

                    if (typeof el?.options === "string") {
                        arSearch = [el.options.toLowerCase()];
                    } else if (Array.isArray(el?.options)) {
                        arSearch = el.options.map(str => str.toLowerCase());
                    }

                    yupSchema = { ...yupSchema, [`qa_${index}`]: Yup.string().lowercase().oneOf(arSearch, `${t('form.incorrect_answer')}`) }
                }

            }

            if (el.type === 'correct_answer') {
                def = { ...def, [`qa_${index}`]: '' }

                if (el.options) {
                    const regex = `/(${el?.options})/i`
                    const arSearch = typeof el.options === "string" ? [el.options.toLowerCase()] : el.options
                    yupSchema = { ...yupSchema, [`qa_${index}`]: Yup.string().lowercase().oneOf(arSearch, `${t('form.incorrect_answer')}`) }
                }

            }
        })
        if (act === 'yup') return yupSchema
        return def

    }, [formFields?.questions, t])


    const defaultValues = useMemo(
        () => setDefault(),
        [setDefault]
    )

    const newSchema = Yup.object().shape(setDefault('yup'))
    const methods = useForm({
        resolver: yupResolver(newSchema),
        defaultValues,
    })


    const {
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
        trigger ,
    } = methods
    const values = watch()

    const curFiled = formFields?.questions.find( (el , ind) => ind === activeStep)

    const handleNextStep = () => {
        const _type = getOurTypeField(curFiled?.type);
        const nameFiled = `${_type}_${activeStep}`
        trigger(nameFiled).then(check => {if(check)setActiveStep(activeStep + 1)})
        // setActiveStep(activeStep + 1)
    }

    const onSubmit = handleSubmit(async (data) => {
        // console.log("DATA", data)
        // if(policy && !checkedPolicy)

        let retObj = []

        formFields.questions.forEach((el, index) => {

            if (el.type === 'list of list' && data[`checkbox_${index}`] !== undefined) {
                const itms = infoForm[`checkbox_${index}`].items

                const push = itms.filter((elms, ind) => ind in data[`checkbox_${index}`])
                // retObj = {...retObj , [`checkbox_${index}`]: push  }
                retObj = [...retObj,
                {
                    title: infoForm[`checkbox_${index}`].title,
                    answers: push
                }
                ]
            } else if (el.type === 'one of list' && data[`radio_${index}`] !== undefined) {
                const radioItms = infoForm[`radio_${index}`].items

                const indexItem = Number(data[`radio_${index}`])

                // retObj = {...retObj , [`radio_${index}`]:  data[`radio_${index}`] === '' ? '': radioItms[indexItem]  }
                const newVal = data[`radio_${index}`] === '' ? '' : radioItms[indexItem]
                retObj = [...retObj,
                {
                    title: infoForm[`radio_${index}`].title,
                    answers: [newVal]
                }
                ]
            } else if (el.type === 'text') {
                retObj = [...retObj,
                {
                    title: infoForm[`text_${index}`].title,
                    answers: [data[`text_${index}`]]
                }
                ]
            } else if (el.type === 'verny_otvet') {
                retObj = [...retObj,
                {
                    title: infoForm[`qa_${index}`].title,
                    answers: [data[`qa_${index}`]]
                }
                ]
                // retObj = {...retObj , [`text_${index}`]: data[`text_${index}`] }
            } else if (el.type === 'correct_answer') {
                retObj = [...retObj,
                {
                    title: infoForm[`qa_${index}`].title,
                    answers: [data[`qa_${index}`]]
                }
                ]
                // retObj = {...retObj , [`text_${index}`]: data[`text_${index}`] }
            } else if (el.type === 'datetime') {
                retObj = [...retObj,
                {
                    title: infoForm[`date_${index}`].title,
                    answers: fDate(data[`date_${index}`] , 'yyyy-MM-dd') ,
                    type: infoForm[`date_${index}`].type,
                    category: infoForm[`date_${index}`].category,
                }
                ]
            }
            else if (el.type === 'phone') {
                retObj = [...retObj,
                {
                    title: infoForm[`phone_${index}`].title,
                    answers: [data[`phone_${index}`]]
                }
                ]
            }
            else if (el.type === 'email') {
                retObj = [...retObj,
                {
                    title: infoForm[`email_${index}`].title,
                    answers: [data[`email_${index}`]]
                }
                ]
            }

            // if(el.type === 'one of list') def = { ...def,[`radio_${index}`] : '' };

        })
        // console.log("DATA OBJ" , retObj);
        const fname = tgUser?.first_name || "-"
        const lname = tgUser?.last_name || "-"

        const postData = {
            answers: retObj,
            tg_id: tgID,
            username: `${fname} ${lname}`,
            tglogin: tgUser?.username || "@",
            avatar: tgUser?.photo_url || "",
        }
        // console.log('postData',postData)

        try {
            setLoading(true)
            const response = await axios.post(`/forms/${curShopId}/${form.id}/fillings`, postData)

            if (response.data.success) {
                enqueueSnackbar(t('system.create_success'))
                setComplete(true)
                // onComplete()
            } else {
                enqueueSnackbar(t('system.create_fail'), { variant: 'error' })
            }

            reset()
            setLoading(false)
            // enqueueSnackbar( 'Create success!');
            // router.push(paths.dashboard.product.root);
            // console.log('DATA', data);
        } catch (er) {
            setLoading(false)
            console.error(er)
        }
    })

    const renderTextField = (title, index) => (

        <Stack spacing={1}>
            {title &&
                <>
                    <Divider sx={{ border: "1px dashed" }} />
                    <Stack direction="row" sx={{ pt: 1 }} alignItems="center">
                        <Iconify icon="iconamoon:arrow-right-2-bold" />
                        <Typography variant="body2">{title}</Typography>
                    </Stack>
                </>
            }
            <RHFTextField
                name={`text_${index}`}
                label="Ответ"
                fullWidth
                variant="filled"
            />
        </Stack>
    )

    const renderEmailField = (title, index) => (

        <Stack spacing={1}>
            {title &&
                <>
                    <Divider sx={{ border: "1px dashed" }} />
                    <Stack direction="row" sx={{ pt: 1 }} alignItems="center">
                        <Iconify icon="iconamoon:arrow-right-2-bold" />
                        <Typography variant="body2">{title}</Typography>
                    </Stack>
                </>
            }
            <RHFTextField
                name={`email_${index}`}
                label="Ответ"
                fullWidth
                variant="filled"
            />
        </Stack>

    )
    const renderQAField = (title, index) => (

        <Stack spacing={1}>
            {title &&
                <>
                    <Divider sx={{ border: "1px dashed" }} />
                    <Stack direction="row" sx={{ pt: 1 }} alignItems="center">
                        <Iconify icon="iconamoon:arrow-right-2-bold" />
                        <Typography variant="body2">{title}</Typography>
                    </Stack>
                </>
            }
            <RHFTextField
                name={`qa_${index}`}
                label="Ответ"
                fullWidth
                variant="filled"
            />
        </Stack>

    )
    const renderRadio = (title, options, index) => {
        const arOpts = options?.map((item, ind) => {
            const obj = { label: item, value: ind }
            return obj
        })

        return (
            <Stack spacing={1}>
                {title &&
                    <>
                        <Divider sx={{ border: "1px dashed" }} />
                        <Stack direction="row" sx={{ pt: 1 }} alignItems="center">
                            <Iconify icon="iconamoon:arrow-right-2-bold" />
                            <Typography variant="body2">{title}</Typography>
                        </Stack>
                    </>
                }
                <RHFRadioGroup
                    name={`radio_${index}`}
                    options={arOpts}
                    sx={{
                        pl: 1,
                        '.MuiFormControlLabel-root': {
                            p: '5px',
                            border: '2px solid',
                            borderColor: 'grey.200',
                            borderRadius: '10px',
                            backgroundColor: theme.palette.background.paper,  // 'grey.200',
                            mb: '1rem !important',
                            '&:has(.Mui-checked)': {
                                // backgroundColor:  'primary.light',
                                borderColor: 'primary.main',
                            },
                        },
                        '.MuiRadio-root': {
                            color: 'primary.dark',
                            '&.Mui-checked': {
                                color: 'primary.dark',

                            },
                        },
                    }}
                />
            </Stack>
        )
    }

    const renderCheckBox = (title, options, index) => {
        const arOpts = options.map((item, ind) => {
            const obj = { label: item, value: ind }
            return obj
        })
        return (
            <Stack spacing={1} >
                {title &&
                    <>
                        <Divider sx={{ border: "1px dashed" }} />
                        <Stack direction="row" sx={{ pt: 1 }} alignItems="center">
                            <Iconify icon="iconamoon:arrow-right-2-bold" />
                            <Typography variant="body2">{title}</Typography>
                        </Stack>
                    </>
                }

                <RHFMultiCheckbox name={`checkbox_${index}`} options={arOpts}
                    sx={{
                        // pl: 1,
                        '.MuiFormControlLabel-root': {
                            p: '0.5rem',
                            // pb: '5px',
                            border: '2px solid',
                            borderColor: 'grey.200',
                            borderRadius: '10px',
                            backgroundColor: theme.palette.background.paper, // 'grey.200',
                            mb: '1rem !important',
                            '&:has(.Mui-checked)': {
                                // backgroundColor: 'primary.light',
                                borderColor: 'primary.main',
                            },
                        },
                        '.MuiCheckbox-root': {
                            color: 'primary.dark',
                            p: '1rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&.Mui-checked': {
                                color: 'primary.dark',
                            },
                            '& input': {
                                position: 'absolute',
                                width: '20px',
                                height: '20px',
                                opacity: 0,
                                zIndex: 1,
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            },
                            '& svg': {
                                position: 'absolute',
                                width: '20px',
                                height: '20px',
                                zIndex: 0,
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            },
                            '& span': {
                                position: 'absolute',
                                width: '20px',
                                height: '20px',
                                zIndex: 0,
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            },
                        },
                    }}
                />
            </Stack>
        )

    }

    const renderDateField = (title, index) => (
        <Stack spacing={1}>
            {title &&
                <>
                    <Divider sx={{ border: "1px dashed" }} />
                    <Stack direction="row" sx={{ pt: 1 }} alignItems="center">
                        <Iconify icon="iconamoon:arrow-right-2-bold" />
                        <Typography variant="body2">{title}</Typography>
                    </Stack>
                </>
            }
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                <DatePicker
                    value={values[`date_${index}`]}
                    label="Выбрать"
                    onChange={(newValue) => setValue(`date_${index}`, newValue)}
                    // renderInput={(params) => <RHFTextField {...params} name={`date_${index}`} variant="filled" />}
                    slotProps={{ textField: { variant: "filled", name: `date_${index}` } }}
                />
            </LocalizationProvider>
        </Stack>
    )

    const renderTimeField = (title, index) => (
        <TimePicker
            // value={timePickerValue}
            label="Время отправки"
            onChange={(newValue) => {
                console.log("onChange", newValue)
                // setTimePickerValue(newValue)
            }}
            renderInput={(params) => <RHFTextField {...params} name="time" />}
        />
    )
    const setPhoneVal = (field, val) => {
        setPhone({ ...phone, [field]: val })
    }

    const renderPhone = (title, index) => (
        <Stack spacing={1}>
            {title &&
                <>
                    <Divider sx={{ border: "1px dashed" }} />
                    <Stack direction="row" sx={{ pt: 1 }} alignItems="center">
                        <Iconify icon="iconamoon:arrow-right-2-bold" />
                        <Typography variant="body2">{title}</Typography>
                    </Stack>
                </>
            }
            <RHFPhoneTextfield
                name={`phone_${index}`}
                label="Телефон"
                variant="filled"
                placeholder={t('form.start_typing')}
            />
        </Stack>
    )

    const maxSteps = formFields?.questions.length;
    const additional_text = formFields?.additional_text || t(`form.you_have_already_filled_out_the_form`)
    const policy = formFields?.policy_link_text ? t(`form.policy`).replace(':link', formFields?.policy_link_text) : null
    if (complete) {
        return (
            // <Alert variant="outlined" severity="success">
            //     {additional_text}
            // </Alert>
            <Markdown
                children={form?.additional_text?.replace(/\\n/g, '\n') || additional_text?.replace(/\\n/g, '\n')}
                sx={{
                    p: 3,
                    '& p, li, ol': {
                        typography: 'body2',
                    },
                    '& ol': {
                        p: 0,
                        display: { md: 'flex' },
                        listStyleType: 'none',
                        '& li': {
                            '&:first-of-type': {
                                minWidth: 240,
                                mb: { xs: 0.5, md: 0 },
                            },
                        },
                    },
                }}
            />
        )
    }
    // const renderCardHeader = component === 'Card' ? (<CardHeader title={formFields[activeStep].title} />)
    //     : (<Typography variant="body1" >{formFields[activeStep].title}</Typography>)

    const renderQuiz = (
        <Stack spacing={1} >
            {(loading ) &&
                <PaymentItemSkeleton />
            }

            {(!loading ) &&
                <>
                    <Typography variant="body2" >{curFiled?.title}</Typography>
                    <LinearProgress variant="buffer" value={Math.round(100 / maxSteps) * activeStep} valueBuffer={Math.round(100 / maxSteps)} />
                    {/* <MobileStepper
                                variant="progress"
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}

                            /> */}
                    <Stack sx={{ pb: 2 }}>
                        {formFields &&
                            <>
                                {formFields.questions.map((el , index) => (
                                    <>
                                        {(el.type === 'text'
                                                && index === activeStep)
                                            &&
                                            <>
                                                {renderTextField(null , index)}
                                            </>
                                        }
                                        {(el.type === 'phone'
                                               && index === activeStep)
                                            &&
                                            <>
                                                {renderPhone(null, index)}
                                            </>
                                        }
                                        {(el.type === 'email'
                                                && index === activeStep)
                                            &&
                                            <>
                                                {renderEmailField(null , index)}
                                            </>
                                        }
                                        {(el.type === 'one of list'
                                                && index === activeStep)
                                            &&
                                            <>
                                                {renderRadio(null , el.options, index)}
                                            </>
                                        }
                                        {(el.type === 'list of list'
                                               && index === activeStep)
                                            &&
                                            <>
                                                {renderCheckBox(null , el.options
                                                    , index)}

                                            </>
                                        }
                                        {(el.type === 'verny_otvet'
                                               && index === activeStep)
                                            &&
                                            <>
                                                {renderQAField(null , index)}

                                            </>
                                        }
                                        {(el.type === 'datetime'
                                               && index === activeStep)
                                            &&
                                            <>
                                                {renderDateField(null , index)}

                                            </>
                                        }
                                    </>
                                ))}

                            </>
                        }
                        {/* {!!error && ( */}
                        {/*    <FormHelperText error sx={{ pt: 1, px: 2 }}> */}
                        {/*        {error.message} */}
                        {/*    </FormHelperText> */}
                        {/* )} */}
                    </Stack>
                </>
            }

            {/* <Button
                size="large"
                variant="contained"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
                sx={{
                    backgroundColor: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                    },
                  }}
            >
                Дальше

            </Button> */}

            {activeStep < maxSteps - 1  &&
                <Button
                    size="large"
                    variant="contained"
                    onClick={handleNextStep}
                    sx={{
                        backgroundColor: 'primary.main',
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                        },
                    }}
                >
                    Дальше
                </Button>
            }

        </Stack>
    )


    return (
        <ComponentBlock
            // title={formFields?.form_name || t('form.form')}
        >

            <FormProvider methods={methods} onSubmit={onSubmit}>
                {renderQuiz}
                {/*    formFields={formFields?.questions} */}
                {/*    node={node} */}
                {/*    loading={isLoading} */}
                {/*    onNext={handleNextStep} */}
                {/*    activeStep={activeStep} */}
                {/*    maxSteps={formFields?.questions.length} */}
                {/*    /> */}

                {activeStep >= maxSteps - 1 &&
                        <>
                            {!policy &&
                                <Button
                                    type="submit"
                                    size="large"
                                    variant="contained"
                                    fullWidth
                                    // disabled
                                    sx={{
                                        backgroundColor: 'primary.main',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                    }}
                                >
                                    Завершить
                                </Button>
                            }


                            {policy !== null &&
                                <>
                                    <Button
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        fullWidth
                                        onClick={handleSubmit(onSubmit)}
                                        sx={{
                                            backgroundColor: 'primary.main',
                                            '&:hover': {
                                                backgroundColor: 'primary.dark',
                                            },
                                        }}
                                        loading={loading}
                                        disabled={!checkedPolicy}
                                    >
                                        Завершить
                                    </Button>
                                    <FormGroup >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkedPolicy}
                                                    onChange={handleChangePolicy}
                                                />
                                            }
                                            label={
                                                <Markdown
                                                    children={policy?.replace(/\\n/g, '\n') || ""}
                                                    sx={{
                                                        p: 1,
                                                        '& p': {
                                                            typography: 'caption',
                                                        },
                                                    }}
                                                />
                                            }
                                            sx={{ mr: 2 }}
                                        />
                                    </FormGroup>
                                </>
                            }
                    </>
                }


            </FormProvider>
        </ComponentBlock>
    )
}

ArticleQuizForm.propTypes = {
    form: PropTypes.object,
    tgID: PropTypes.number,
    tgUser: PropTypes.object,
    onComplete: PropTypes.func ,
}


function getOurTypeField (type){
    let tp = type;
    switch(type){
        case 'list of list':
            tp = 'checkbox'
            break;
        case 'one of list':
            tp = 'radio'
            break;
        case 'verny_otvet':
            tp = 'qa'
            break;
        case 'correct_answer':
            tp = 'qa'
            break;
        default: tp = type;
    }
    return tp;
}
